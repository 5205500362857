import {useState} from "react";
import s from './modal.module.scss';
import api from "../../api";
import close from "../../img/close.svg";
import InputMask from 'react-input-mask';
import Swal from 'sweetalert2';

export const Ballance = ({onClose}) => {
    const [price, setPrice] = useState('');
    const [phone, setPhone] = useState('');

    const handleOutsideClick = (e) => {
        if (e.target.classList.contains(s.modal)) {
            onClose();
        }
    };

    const payBallance = async () => {
        try {
            const phoneNumberToSend = phone.replace(/\D/g, '').slice(1); // Получаем формат 9999999999
            await api.post('/transactions', {
                amount: price,
                phoneNumber: phoneNumberToSend
            });



            // Сначала закрываем модалку
            onClose();

            // После закрытия вызываем SweetAlert
            setTimeout(() => {
                Swal.fire({
                    icon: 'success',
                    title: 'Запрос успешен!',
                    text: 'Счет будет выставлен в течение 60 секунд. Проверьте приложение Kaspi для оплаты.',
                    confirmButtonText: 'ОК'
                });
            }, 300); // Добавляем небольшую задержку для плавного закрытия модалки

        } catch (error) {
            // Сначала закрываем модалку
            onClose();

            // После закрытия вызываем SweetAlert с ошибкой
            setTimeout(() => {
                Swal.fire({
                    icon: 'error',
                    title: 'Ошибка!',
                    text: `Произошла ошибка: ${error.response ? error.response.data.message : error.message}`,
                    confirmButtonText: 'ОК'
                });
            }, 300);
        }
    };

    return (
        <div className={s.modal} onClick={handleOutsideClick}>
            <div className={s.modal__block}>
                <div className={s.modal__title}>
                    <div>Пополнить балланс</div>
                    <div className={s.modal__close} onClick={onClose}>
                        <img src={close} alt=""/>
                    </div>
                </div>
                <div className={s.modal__bal__block}>
                    <div className={s.modal__bal}>
                        <input
                            type="number"
                            placeholder="Введите сумму"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                        />
                    </div>
                    <div className={s.modal__bal}>
                        <InputMask
                            mask="+7(999) 999-99-99"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder="Введите номер Kaspi"
                        />
                    </div>
                </div>

                <div className={s.modal__btns}>
                    <div className={s.modal__btn} onClick={payBallance}>Пополнить балланс</div>
                </div>
            </div>
        </div>
    );
};
