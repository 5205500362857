import React from "react";

const ProfileHeader = ({onTelegramClick, onBallanceClick}) => {
    return (
        <div className="profile__header">
            <div className="profile__header--item" onClick={onTelegramClick}>
                Подключить Telegram
            </div>
            <div className="profile__header--item">Подключить whatsapp</div>

            <div className="profile__header--item" onClick={onBallanceClick}>
                Пополнить баланс
            </div>
        </div>
    );
};

export default ProfileHeader;
