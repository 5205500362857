import React, {useEffect, useState} from "react";
import arrowRight from "../../img/arrow__right.svg";
import main from '../../img/main.png';
import {Link} from "react-router-dom";
import {Spin} from 'antd';

const Elements = () => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    useEffect(() => {
        const image = new Image();
        image.src = main;

        image.onload = () => {
            setIsImageLoaded(true);
        };
    }, []);

    return (
        <section className="elements">
            <div
                className="elements__block"
                role="presentation"
                style={{height: isImageLoaded ? 'auto' : '500px'}} // Устанавливаем фиксированную высоту, если изображение не загружено
            >
                {!isImageLoaded && (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%'
                    }}>
                        <Spin size="large" tip="Загрузка изображения..."/>
                    </div>
                )}
                <img
                    src={main}
                    alt="Главная страница Tend.kz"
                    className='elements__block--img'
                    style={{display: isImageLoaded ? 'block' : 'none'}}
                />
            </div>
            <div className="elements__text">
                <div className="elements__text--left">
                    <Link to={'/profile'} className="elements__text-normal">Личный кабинет</Link>
                    <p className="elements__text--opacity">Доступ к админ панели для управления тендерами</p>
                </div>
                <Link to={'/register'} className="elements__text--btn">
                    Зарегистрироваться <img src={arrowRight} alt="Стрелка вправо"/>
                </Link>
            </div>
        </section>
    );
};

export default Elements;
