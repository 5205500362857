import React, { useState } from "react";
import { Modal, Button, Checkbox, Empty } from 'antd';

const Employees = ({ employees, setEmp, filters, handleAssignFilters }) => {
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = (employee) => {
        setSelectedEmployee(employee);
        setSelectedFilters(employee.assignedFilters.map(f => f.filterId));
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedEmployee(null);
        setSelectedFilters([]);
    };

    const toggleFilter = (filterId) => {
        if (selectedFilters.includes(filterId)) {
            setSelectedFilters(selectedFilters.filter(id => id !== filterId));
        } else {
            setSelectedFilters([...selectedFilters, filterId]);
        }
    };

    const handleApplyFilters = () => {
        handleAssignFilters(selectedEmployee.employeeId, selectedFilters);
        closeModal();
    };

    return (
        <div className="profile__emploee">
            <div className="profile__emploee--top">
                <div className="profile__subtitle">Список сотрудников</div>
                <Button type="primary" className="profile__emploee--btn" onClick={() => setEmp(true)}>
                    Добавить
                </Button>
            </div>
            <div className="profile__emploee--block">
                {employees.length > 0 ? (
                    employees.map((employee) => (
                        <div className="profile__emploee--item" key={employee.employeeId}>
                            <div className="profile__emploee--name">{employee.employeeName}</div>
                            <div className="profile__emploee--role" onClick={() => openModal(employee)}>
                                {employee.assignedFilters.length > 0
                                    ? employee.assignedFilters.map(f => f.filterName).join(', ')
                                    : 'Фильтры не назначены'}
                            </div>
                        </div>
                    ))
                ) : (
                    <Empty description="Нет сотрудников" />
                )}
            </div>

            <Modal
                title={`Назначение фильтров для ${selectedEmployee?.employeeName}`}
                open={modalIsOpen} // Заменено visible на open
                onCancel={closeModal}
                footer={[
                    <Button key="back" onClick={closeModal}>
                        Отмена
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleApplyFilters}>
                        Применить
                    </Button>,
                ]}
            >
                <div className="filter-list">
                    {filters.map(filter => (
                        <Checkbox
                            key={filter.filterId}
                            checked={selectedFilters.includes(filter.filterId)}
                            onChange={() => toggleFilter(filter.filterId)}
                        >
                            {filter.filterName}
                        </Checkbox>
                    ))}
                </div>
            </Modal>
        </div>
    );
};

export default Employees;
