import React from "react";
import icon from '../../img/profile.svg';

const Personal = ({profile}) => {

    return (
        <div className="profile__personal">
            <div className="profile__icon">
                <img src={icon} alt=""/>
            </div>
            <div className="profile__name">{profile.firstName} {profile.lastName}</div>
            <div className="profile__number">{profile.phone}</div>
        </div>
    );
};

export default Personal;
