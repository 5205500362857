import React from "react";
import Employees from "./Employees";
import EmployeeAdd from "./EmployeeAdd";

const EmpHand = ({emp, setEmp, employees, filters, handleAssignFilters}) => {
    return (
        <>
            {emp ? (
                <EmployeeAdd
                    filters={filters}
                    setEmp={setEmp}
                />
            ) : (
                <Employees
                    employees={employees}
                    setEmp={setEmp}
                    filters={filters}
                    handleAssignFilters={handleAssignFilters}
                />
            )}
        </>
    );
};

export default EmpHand;
