import axios from 'axios';

const index = axios.create({
    baseURL: 'https://tend.kz/api', // Замените на ваш базовый URL
});

index.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

export default index;
