import one from '../../img/1.png'
import two from '../../img/2.png'
import tre from '../../img/3.png'
import four from '../../img/4.png'

const Solution = () => {
    return (
        <>
            <section className="solution">
                <h2 className="solution__title">
                    <span className='active'>Решение</span> для каждого этапа работы
                </h2>
                <div className="solution__block">
                    <article className="solution__card">
                        <div className="solution__card--text">
                            <h3>Быстрый подбор</h3>
                            <p>Наши инструменты поиска подходящих тендеров настроены на 100 тендерных площадок Казахстана.</p>
                        </div>
                        <div className="image">
                            <img src={one} alt=""/>
                        </div>
                    </article>
                    <div className="solution__container ">
                        <article className="solution__card solution__card--small tal">
                            <div className="solution__card--text">
                                <h3>Командные рабочие зоны</h3>
                                <p>Вмещайте в команду до 5 сотрудников и не переплачивайте.</p>
                            </div>
                            <div className="image">
                                <img src={two} alt=""/>
                            </div>
                        </article>
                        <article className="solution__card solution__card--small p48">
                            <div className="image">
                                <img src={tre} alt=""/>
                            </div>
                            <div className="solution__card--text">
                                <h3>Проверка агентов</h3>
                                <p>Наглядный анализ и проверка благонадежности участников закупок</p>
                            </div>
                        </article>
                    </div>
                    <article className="solution__card df">
                        <div className="solution__card--text">
                            <h3>Маржинальная выборка</h3>
                            <p>Tend.kz - использует машинное обучение для анализа лотов на маржинальность, тем самым экономит вам время на подсчетах прибыли.</p>
                        </div>
                        <div className="image">
                            <img src={four} alt=""/>
                        </div>
                    </article>
                </div>
            </section>
        </>
    );
};

export default Solution
