import React from "react";
import Personal from "./Personal";
import Company from "./Company";
import ProfileHeader from "./ProfileHeader";

const ProfileTop = ({profile, onTelegramClick, onBallanceClick}) => {
    return (
        <div className="profile__top">
            <Personal profile={profile}/>
            <div className='profile__right'>
                <Company profile={profile}/>
                <ProfileHeader
                    onTelegramClick={onTelegramClick}
                    onBallanceClick={onBallanceClick} // Исправлено: передача функции
                />
            </div>
        </div>
    );
};

export default ProfileTop;
