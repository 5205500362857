import { TendersGridTop } from "./TendersGridTop";
import { Sidebar } from "./Sidebar";
import { TendersList } from "./TendersList";
import { ClipLoader } from 'react-spinners';
import { Empty } from 'antd';

const ColorScale = () => {
    return (
        <div className="color-scale-container">
            <div className="color-scale__label">Шкала маржинальности:</div>
            <div className="color-scale">
                <div className="color-scale__bar"></div>
            </div>
            <div className="color-scale__legend">
                <span className="low-margin">0%</span>
                <span className="high-margin">100%</span>
            </div>
        </div>
    );
};

export const TendersGrid = ({
                                filters,
                                tenders,
                                toggleFilter,
                                activeFilters,
                                page,
                                totalPages,
                                setPage,
                                loading,
                                lastTenderElementRef,
                                onSortChange
                            }) => {
    return (
        <div className='TendersGrid'>
            <TendersGridTop onSortChange={onSortChange} />
                {loading && tenders.length === 0 ? (
                    <div className="loader-container">
                        <ClipLoader size={50} color={"#123abc"} loading={loading} />
                    </div>
                ) : (
                    <div className="dcc">
                        {tenders.length === 0 ? (
                            <Empty description="Нет доступных тендеров" />
                        ) : (
                            <>
                                <Sidebar
                                    filters={filters}
                                    toggleFilter={toggleFilter}
                                    activeFilters={activeFilters}
                                />
                                {/*<ColorScale />*/}
                                <TendersList tenders={tenders} lastTenderElementRef={lastTenderElementRef} />
                            </>
                        )}
                    </div>
                )}
            {loading && tenders.length > 0 && (
                <div className="loader-container">
                    <ClipLoader size={50} color={"#123abc"} loading={loading} />
                </div>
            )}
        </div>
    );
};
