import Main from "../components/Home/Title";
import Elements from "../components/Home/Elements";
import Solution from "../components/Home/Solution";
import Plans from "../components/Home/Plans";
import Slider from "../components/Home/Slider";
import Faq from "../components/Profile/Faq";
import { Helmet } from 'react-helmet';
function Home() {
    return (
        <div className="container">
            <Helmet>
                <title>Tend.kz - Главная</title>
                <meta name="description" content="Tend.kz — умный агрегатор тендеров Казахстана." />
                <link rel="canonical" href="https://tend.kz/" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Tend.kz" />
                <meta name="publisher" content="Tend.kz" />
            </Helmet>
            <Main/>
            <Elements/>
            <Solution/>
            <Slider/>
            <Plans/>
            <Faq/>
        </div>
    );
}

export default Home;
