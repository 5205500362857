import {TenderItem} from "./TenderItem";

export const TendersList = ({tenders, lastTenderElementRef}) => {
    const generateRandomKey = (id, index) => {
        const randomString = Math.random().toString(36).substring(2, 8);
        return `${id}-${index}-${randomString}`;
    };

    return (
        <div className="tendersList">
            {tenders.map((tender, index) => {
                if (index === tenders.length - 10) {
                    return (
                        <div ref={lastTenderElementRef} key={generateRandomKey(tender.id, index)}>
                            <TenderItem tender={tender}/>
                        </div>
                    );
                } else {
                    return <TenderItem key={generateRandomKey(tender.id, index)} tender={tender}/>;
                }
            })}
        </div>
    );
}

