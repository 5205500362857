import React from 'react';
import {Collapse, Typography} from 'antd';

const {Panel} = Collapse;
const {Title} = Typography;

const FAQ = () => {
    const faqData = [
        {
            question: "Какие тендерные площадки поддерживаются на платформе Tend.kz?",
            answer: "Платформа поддерживает более 100 тендерных площадок Казахстана, включая госзакупки, Самрук-Казына, NadLoc, Байтерек, КазАгро и другие."
        },
        {
            question: "Как работает ИИ-анализ тендеров на Tend.kz?",
            answer: "ИИ-анализ оценивает маржинальность и прибыльность тендеров, помогает выбрать оптимальные лоты и прогнозирует успех на основе исторических данных."
        },
        {
            question: "Есть ли бесплатный пробный доступ?",
            answer: "Да, Tend.kz предлагает бесплатный доступ на 14 дней с полным функционалом платформы для ознакомления."
        },
        {
            question: "Как выбрать подходящий тарифный план?",
            answer: "Выбор плана зависит от ваших потребностей. 'Старт' предлагает основные функции, а 'Бизнес' и 'Премиум' включают ИИ-анализ, юридические рекомендации и прогнозирование маржинальности."
        },
        {
            question: "Как я могу оплатить тарифный план?",
            answer: "Оплатить тарифный план можно через банковские карты, электронные кошельки и прямым переводом. Дополнительные способы оплаты доступны в личном кабинете."
        },
        {
            question: "Могу ли я изменить тарифный план после оплаты?",
            answer: "Да, вы можете изменить тарифный план в любое время. При этом стоимость оставшихся дней на текущем тарифе будет учтена."
        },
        {
            question: "Какие отчеты доступны в тарифе 'Премиум'?",
            answer: "Тариф 'Премиум' включает персонализированные отчеты по тендерам, отраслевые прогнозы и возможность выгрузки данных в Excel."
        },
        {
            question: "Как настроить рассылку уведомлений по тендерам?",
            answer: "В вашем профиле можно настроить индивидуальные уведомления по email, Telegram или WhatsApp, выбрав нужные категории тендеров и ключевые слова."
        },
        {
            question: "Можно ли работать с несколькими сотрудниками в одной учетной записи?",
            answer: "Да, тарифы 'Бизнес' и 'Премиум' поддерживают добавление до 3 сотрудников с возможностью распределения задач и контроля доступа."
        },
        {
            question: "Как я могу связаться со службой поддержки?",
            answer: "Наша служба поддержки доступна круглосуточно. Вы можете связаться с нами через чат на сайте, по телефону, WhatsApp или Telegram."
        }
    ];

    return (
        <section className="faq">
            <h2 className="solution__title">
                Часто задаваемые <span className='active'>вопросы</span>
            </h2>
            <Collapse accordion bordered={false} style={{backgroundColor: 'transparent', marginTop: 48}}>
                {faqData.map((item, index) => (
                    <Panel header={<span style={{color: 'black', fontSize: 18}}>{item.question}</span>} key={index}
                           style={{backgroundColor: 'transparent'}}>
                        <p style={{color: 'black'}}>{item.answer}</p>
                    </Panel>
                ))}
            </Collapse>
        </section>
    );
};

export default FAQ;
