import React, { useRef } from 'react';
import right from "./../../img/slider__arrow--right.svg";
import left from "../../img/slider__arrow--left.svg";
import five from '../../img/5.png'
import six from "../../img/6.png";
const Slider = () => {
    const sliderRef = useRef(null);

    const scrollLeft = () => {
        if (sliderRef.current) {
            sliderRef.current.scrollBy({
                left: -sliderRef.current.clientWidth,
                behavior: 'smooth'
            });
        }
    };

    const scrollRight = () => {
        if (sliderRef.current) {
            sliderRef.current.scrollBy({
                left: sliderRef.current.clientWidth,
                behavior: 'smooth'
            });
        }
    };

    return (
        <section className="edge">
            <h2 className="edge__title">
                <span className='active'>Преимущества</span> использования Tend.kz
            </h2>

            <div className="edge__slider" ref={sliderRef}>
                <div className="edge__block">
                    <div className="edge__block--text">
                        <h3>Единая платформа тендеров</h3>
                        <p>Собираем тендеры из всех главных площадок Казахстана в одном месте, чтобы облегчить ваш поиск.</p>
                    </div>
                    <div className="edge__block--image">
                        <img src={six} alt="Единая платформа тендеров"/>
                    </div>
                </div>
                <div className="edge__block">
                    <div className="edge__block--text">
                        <h3>Анализ тендеров на основе ИИ</h3>
                        <p>Используйте наш ИИ для оценки маржинальности и выгоды тендера, чтобы принимать обоснованные решения.</p>
                    </div>
                    <div className="edge__block--image">
                        <img src={five} alt="Анализ тендеров на основе ИИ"/>
                    </div>
                </div>
                <div className="edge__block">
                    <div className="edge__block--text">
                        <h3>Удобное управление командами</h3>
                        <p>Создавайте рабочие зоны для нескольких сотрудников, назначайте роли и упрощайте работу в команде.</p>
                    </div>
                    <div className="edge__block--image">
                        <img src={six} alt="Удобное управление командами"/>
                    </div>
                </div>
                <div className="edge__block">
                    <div className="edge__block--text">
                        <h3>Персонализированные уведомления</h3>
                        <p>Настройте рассылки по интересующим вас тендерам, получая важные обновления на email, Telegram или WhatsApp.</p>
                    </div>
                    <div className="edge__block--image">
                        <img src={five} alt="Персонализированные уведомления"/>
                    </div>
                </div>
            </div>
            <div className="slider__arrows">
                <img src={left} alt='left' onClick={scrollLeft}/>
                <img src={right} alt='right' onClick={scrollRight}/>
            </div>
        </section>
    );
};


export default Slider;
