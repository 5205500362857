export const TenderData = ({title, content, needPlan}) => {
    return (
        <div className="tenderPage__item">
            <div className="tenderPage__title">
                {title}
            </div>
            <div className={`tenderPage__content ${needPlan ? 'needPlan' : ''}`}>
                {content}
            </div>
        </div>
    )
}
