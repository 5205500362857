import React, { useState } from "react";

const Plans = () => {
    const [period, setPeriod] = useState('month');

    const plan = {
        month: [
            {
                id: 1,
                title: "Пробный",
                price: "0 ",
                description: "Бесплатный доступ агрегатора на 14 дней\nДоступ ко всем функциям\nОбучение работе с платформой"
            },
            {
                id: 2,
                title: "Старт",
                price: "9 000 ",
                description: "Доступ к базе тендеров \n Доступ к ИИ аналитике тендеров \nИндивидуальная email рассылка \n Индивидуальная Telegram рассылка \n Индивидуальная WhatsApp рассылка \nЛимит фильтров - 6 \n Служба поддержки 24/7 "
            },
            {
                id: 3,
                title: "Бизнес",
                price: "12 000 ",
                description: "Доступ к базе тендеров \n Полный доступ к ИИ аналитике тендеров \n Юридические рекомендации к каждому тендеру \n Аналитика документации к каждому тендеру \n Прогнозирование маржинальности \n Аналитика оптимальной ставки \n Аналитика похожих тендеров \n  Сортировка по маржинальности \n Поиск тендеров по прайс листу \n Регистрация сотрудников \n Лимит сотрудников - 3 \n Индивидуальная email рассылка \n Индивидуальная Telegram рассылка \n Индивидуальная WhatsApp рассылка \nЛимит фильтров - 12 \n Служба поддержки 24/7 \n Личный менеджер"
            },
            {
                id: 4,
                title: "Премиум",
                price: "18 000 ",
                description: "Доступ к базе тендеров \n Полный доступ к ИИ аналитике тендеров \n Юридические рекомендации к каждому тендеру \n Аналитика документации к каждому тендеру \n Персонализированные отчеты по тендерам \n Прогнозы на основе отраслевых данных \n Выгрузка тендеров в excel \n Прогнозирование маржинальности \n Аналитика оптимальной ставки \n Аналитика похожих тендеров \n  Сортировка по маржинальности \n Поиск тендеров по прайс листу \n Регистрация сотрудников \n Лимит сотрудников - 3 \n Индивидуальная email рассылка \n Индивидуальная Telegram рассылка \n Индивидуальная WhatsApp рассылка \nЛимит фильтров - 12 \n Служба поддержки 24/7 \n Личный менеджер"
            }
        ],
        year: [
            {
                id: 5,
                title: "Пробный",
                price: "0 ",
                description: "Бесплатный доступ агрегатора на 14 дней\nДоступ ко всем функциям\nОбучение работе с платформой"
            },
            {
                id: 6,
                title: "Старт",
                price: "75 000 ",
                description: "Доступ к базе тендеров \n Доступ к ИИ аналитике тендеров \nИндивидуальная email рассылка \n Индивидуальная Telegram рассылка \n Индивидуальная WhatsApp рассылка \nЛимит фильтров - 6 \n Служба поддержки 24/7 "
            },
            {
                id: 7,
                title: "Бизнес",
                price: "100 000 ",
                description: "Доступ к базе тендеров \n Полный доступ к ИИ аналитике тендеров \n Юридические рекомендации к каждому тендеру \n Аналитика документации к каждому тендеру \n Прогнозирование маржинальности \n Аналитика оптимальной ставки \n Аналитика похожих тендеров \n  Сортировка по маржинальности \n Поиск тендеров по прайс листу \n Регистрация сотрудников \n Лимит сотрудников - 3 \n Индивидуальная email рассылка \n Индивидуальная Telegram рассылка \n Индивидуальная WhatsApp рассылка \nЛимит фильтров - 12 \n Служба поддержки 24/7 \n Личный менеджер"
            },
            {
                id: 8,
                title: "Премиум",
                price: "150 000 ",
                description: "Доступ к базе тендеров \n Полный доступ к ИИ аналитике тендеров \n Юридические рекомендации к каждому тендеру \n Аналитика документации к каждому тендеру \n Персонализированные отчеты по тендерам \n Прогнозы на основе отраслевых данных \n Выгрузка тендеров в excel \n Прогнозирование маржинальности \n Аналитика оптимальной ставки \n Аналитика похожих тендеров \n  Сортировка по маржинальности \n Поиск тендеров по прайс листу \n Регистрация сотрудников \n Лимит сотрудников - 3 \n Индивидуальная email рассылка \n Индивидуальная Telegram рассылка \n Индивидуальная WhatsApp рассылка \nЛимит фильтров - 12 \n Служба поддержки 24/7 \n Личный менеджер"
            }
        ]
    };

    return (
        <section className="plans">
            <div className="plans__title plan__header">Тарифы</div>
            <div className="plans__header">
                <div
                    className={`plans__header--item ${period === 'month' ? 'active' : ''}`}
                    onClick={() => setPeriod('month')}
                >
                    Месяц
                </div>
                <div
                    className={`plans__header--item ${period === 'year' ? 'active' : ''}`}
                    onClick={() => setPeriod('year')}
                >
                    Год
                </div>
            </div>
            <div className="plans__block">
                {plan[period].map((item) => (
                    <div key={item.id} className="plans__item">
                        <div className="plans__item--block">
                            <div className="plans__item--top">
                                <div className="plans__item--title">{item.title}</div>
                                <div className="plans__item--price">{item.price}₸</div>
                            </div>
                            <div className="plans__item--text">
                                {item.description.split('\n').map((line, index) => (
                                    <p key={index}>{line}<br /></p>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Plans;
