import statusActive from '../../img/status__active.svg';
import star from '../../img/star.svg';
import note from '../../img/note.svg';
import {useState} from "react";
import {Link} from "react-router-dom";

export const TenderItem = ({tender}) => {
    const [isFavorite, setIsFavorite] = useState(false);

    const handleFavoriteClick = () => {
        setIsFavorite(!isFavorite);
    }

    // Функция для вычисления цвета с opacity 0.05 на основе процента маржинальности (от синего к зеленому)
    const getMarginColor = (percent) => {
        const blue = Math.min(255, Math.round(255 * (1 - percent / 100)));
        const green = Math.min(255, Math.round(255 * (percent / 100)));
        return `rgba(0, ${green}, ${blue}, 0.05)`;
    };

    return (
        <div className="tender" style={{backgroundColor: getMarginColor(tender.extended_info.marginality_procent)}}>
            <Link to={`/tender/${tender.id}`} className="tender__name">
                {tender.announce.name}
            </Link>
            <div className="tender__info">
                <div className="tender__info--item">
                    <div className="tender__info--title">
                        Цена
                    </div>
                    <div className="tender__info--text">
                        {tender.planned_total_amount.toLocaleString()} ₸
                    </div>
                </div>
                <div className="tender__info--item">
                    <div className="tender__info--title">
                        Город
                    </div>
                    <div className="tender__info--text">
                        {tender.delivery_location ? tender.delivery_location.split(',')[1].trim() : 'Не указан'}
                    </div>
                </div>
                <div className="tender__info--item">
                    <div className="tender__info--title">
                        Начало:
                    </div>
                    <div className="tender__info--text">
                        {new Date(tender.announce.offers_start_date).toLocaleDateString()}
                    </div>
                </div>
                <div className="tender__info--item">
                    <div className="tender__info--title">
                        Окончание:
                    </div>
                    <div className="tender__info--text">
                        {new Date(tender.announce.offers_end_date).toLocaleDateString()}
                    </div>
                </div>
                <div className="tender__info--item">
                    <div className="tender__info--title">
                        Маржинальность:
                    </div>
                    <div className="tender__info--text">
                        {tender.extended_info.marginality_procent} %
                    </div>
                </div>
            </div>
            <div className="tender__bottom">
                <div className="tender__bottom--left">
                    <img src={statusActive} alt=""/>
                    <span>
                        {tender.announce.status === 'Опубликовано' ? 'Прием заявок' : 'Завершено'}
                    </span>
                </div>
                <div className="tender__bottom--right">
                    <div className={`tender__btn ${isFavorite ? 'favorite' : ''}`} onClick={handleFavoriteClick}>
                        В избранное
                        <img src={star} alt=""/>
                    </div>
                    <div className="tender__btn">
                        Добавить заметку
                        <img src={note} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    );
}
