import React, { useState } from "react";
import { Modal, Input, Button, List, Form, Tooltip, Space } from "antd";
import { PlusOutlined, InfoCircleOutlined } from "@ant-design/icons";
import api from "../../api";
import * as XLSX from "xlsx";

const AddProductModal = ({ onClose }) => {
    const [products, setProducts] = useState([{ name: "", price: "", keywords: "" }]);
    const [importedProducts, setImportedProducts] = useState([]);

    const addProductField = () => {
        setProducts([...products, { name: "", price: "", keywords: "" }]);
    };

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const newProducts = [...products];
        newProducts[index][name] = value;
        setProducts(newProducts);
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        const fileExtension = file.name.split(".").pop();

        reader.onload = (event) => {
            const fileContent = event.target.result;

            try {
                if (fileExtension === "json") {
                    // Если файл JSON
                    const jsonData = JSON.parse(fileContent);
                    const imported = jsonData.map((item) => ({
                        name: item.name || "",
                        price: item.price || "",
                        keywords: item.keywords || "",
                    }));
                    setImportedProducts(imported);
                } else if (fileExtension === "xlsx") {
                    // Если файл XLSX
                    const workbook = XLSX.read(fileContent, { type: "binary" });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(sheet);

                    const imported = jsonData.map((item) => ({
                        name: item.name || "",
                        price: item.price || "",
                        keywords: item.keywords || "",
                    }));
                    setImportedProducts(imported);
                } else {
                    console.error("Неподдерживаемый формат файла");
                }
            } catch (error) {
                console.error("Ошибка при обработке файла:", error);
            }
        };

        if (fileExtension === "xlsx") {
            reader.readAsBinaryString(file); // Для XLSX файлов
        } else {
            reader.readAsText(file); // Для JSON файлов
        }
    };

    const handleSubmit = async () => {
        const formattedProducts = [...products, ...importedProducts].map((product) => ({
            ...product,
            keywords: product.keywords.split(",").map((keyword) => keyword.trim()),
        }));

        try {
            await api.post("/price-list/add", { items: formattedProducts });
            onClose();
        } catch (error) {
            console.error("Error adding products:", error);
        }
    };

    return (
        <Modal
            title="Добавить товары"
            open={true} // Заменено visible на open
            onCancel={onClose}
            footer={[
                <Button key="close" onClick={onClose}>
                    Закрыть
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Сохранить
                </Button>,
            ]}
        >
            <Form layout="vertical">
                {products.map((product, index) => (
                    <Space key={index} direction="vertical" size="middle" style={{ width: "100%" }}>
                        <Form.Item
                            label={
                                <span>
                                    Название товара&nbsp;
                                    <Tooltip title="Введите название товара">
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                </span>
                            }
                        >
                            <Input
                                placeholder="Название товара"
                                value={product.name}
                                name="name"
                                onChange={(event) => handleInputChange(index, event)}
                            />
                        </Form.Item>

                        <Form.Item
                            label={
                                <span>
                                    Цена&nbsp;
                                    <Tooltip title="Укажите цену товара в тенге">
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                </span>
                            }
                        >
                            <Input
                                type="number"
                                placeholder="Цена"
                                value={product.price}
                                name="price"
                                onChange={(event) => handleInputChange(index, event)}
                            />
                        </Form.Item>

                        <Form.Item
                            label={
                                <span>
                                    Ключевые слова&nbsp;
                                    <Tooltip title="Введите ключевые слова, разделенные запятыми">
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                </span>
                            }
                        >
                            <Input
                                placeholder="Ключевые слова (через запятую)"
                                value={product.keywords}
                                name="keywords"
                                onChange={(event) => handleInputChange(index, event)}
                            />
                        </Form.Item>
                    </Space>
                ))}

                <Button type="dashed" onClick={addProductField} block icon={<PlusOutlined />}>
                    Добавить еще товар
                </Button>

                {importedProducts.length > 0 && (
                    <>
                        <h3>Импортированные товары</h3>
                        <List
                            bordered
                            dataSource={importedProducts}
                            renderItem={(product) => (
                                <List.Item>
                                    {product.name} — {product.price} тг ({product.keywords})
                                </List.Item>
                            )}
                        />
                    </>
                )}

                <Form.Item label="Импорт товаров (XLSX или JSON)">
                    <input type="file" accept=".xlsx, .json" onChange={handleFileUpload} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddProductModal;
