import {useState} from "react";
import s from './modal.module.scss';
import api from "../../api";
import close from "../../img/close.svg";

export const Telegram = ({onClose}) => {
    const [code, setCode] = useState('********');
    const [isCopy, setIsCopy] = useState(false);
    const [error, setError] = useState('');

    const getCode = async () => {
        try {
            if (code === '********') {
                const response = await api.post('/companies/telegram-link');
                setCode(response.data.code);
            } else {
                setError('Код уже сгенерирован');
            }
        } catch (error) {
            console.error("Error fetching code data:", error);
        }
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(code)
            .then(() => {
                setIsCopy(true);
            })
            .catch(err => {
                console.error('Ошибка при копировании:', err);
            });
    };

    const handleOutsideClick = (e) => {
        if (e.target.classList.contains(s.modal)) {
            onClose();
        }
    };

    return (
        <div className={s.modal} onClick={handleOutsideClick}>
            <div className={s.modal__block}>
                <div className={s.modal__title}>
                    <div>Подключить Telegram</div>
                    <div className={s.modal__close} onClick={onClose}>
                        <img src={close} alt=""/>
                    </div>
                </div>
                <div className={s.modal__code}>
                    {code}
                </div>
                {error !== '' && <div className={s.modal__error}>{error}</div>}

                <div className="modal__info">
                    Отправьте Ваш код боту <a href="https://t.me/tenders_event_bot" target='_blank'
                                              rel="noreferrer">@tenders_event_bot</a>
                </div>
                <div className={s.modal__btns}>
                    <div className={s.modal__btn} onClick={getCode}>Получить код</div>
                    {code !== '********' && (
                        <div className={isCopy ? s.modal__btn_green : s.modal__btn_second} onClick={copyToClipboard}>
                            {isCopy ? 'Скопировано' : 'Скопировать'}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
