import logo from '../../img/logo.svg'
import tg from '../../img/tg.svg'
import whatsapp from '../../img/whatsapp.svg'
import insta from '../../img/insta.svg'
import AuthLinks from "../Layout/AuthLinks";
import {Link, NavLink} from "react-router-dom";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__top">
                <div className="footer__logo">
                    <Link to='/'><img src={logo} alt='logo' className='logo'/></Link>

                </div>
                <div className="footer__links">
                    <div className="footer__social">
                        <a href="https://t.me/fixtenders" target="_blank" rel="noreferrer">
                            <img src={tg} alt='logo' className='telegram'/>
                        </a>
                        <a href="https://whatsapp.com/channel/0029VarC5eXFSAt4iYpecV3x" target='_blank'
                           rel="noreferrer">
                            <img src={whatsapp} alt='logo' className='whatsapp'/>
                        </a>
                        <a href="https://instagram.com/tend_zakup" target="_blank" rel="noreferrer">
                            <img src={insta} alt='logo' className='instagram'/>
                        </a>
                    </div>
                    <AuthLinks isFooter={true}/>

                </div>
            </div>
            <div className="footer__bottom">
                <div className="footer__nav">
                    <NavLink to='/' className={({isActive}) => isActive ? 'active' : ''}>
                        <li>Главная</li>
                    </NavLink>
                    <NavLink to='/tenders' className={({isActive}) => isActive ? 'active' : ''}>
                        <li>Тендеры</li>
                    </NavLink>
                    <NavLink to='/plans' className={({isActive}) => isActive ? 'active' : ''}>
                        <li>Тарифы</li>
                    </NavLink>
                    <NavLink to={'/license'}>Лицензионное соглашение</NavLink>
                    <NavLink to={'/oferta'}>ДОГОВОР-ОФЕРТА</NavLink>
                </div>
                <div className="footer__copyright">
                    © Tend.kz 2024. Все права защищены. <br/>
                    При использовании материалов гиперссылка на tend.kz обязательна. <br/>
                    ИП ПУХОВ, СЕВЕРО-КАЗАХСТАНСКАЯ ОБЛАСТЬ, Г.ПЕТРОПАВЛОВСК
                </div>
            </div>
        </footer>

    );
};

export default Footer
