const Main  = () => {
    return (
        <main className="main">
            <h1 className="main__title">
                <span className='active'>Tend</span>ерный агрегатор Казахстана
            </h1>
            <p className="main__sub">
                Платформа для поиска тендеров по Казахстану: госзакупки, Самрук-Казына, NadLoc, Байтерек, КазАгро и другие — все тендерные площадки на одной платформе.
            </p>
        </main>
    );
};


export default Main
