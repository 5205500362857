import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import EmpHand from "../components/Profile/EmpHand";
import ProfileTop from "../components/Profile/ProfileTop";
import FiltHand from "../components/Profile/FiltHand";
import api from "../api";
import {Telegram} from "../components/Modals/Telegram";
import {Ballance} from "../components/Modals/Ballance";
import PriceList from "../components/Profile/PriceList";
import AddProductModal from "../components/Modals/AddProductModal";
import {Helmet} from "react-helmet";
import {Spin} from 'antd';

const Profile = () => {
    const location = useLocation();
    const [emp, setEmp] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [showFiltersAdd, setShowFiltersAdd] = useState(false);
    const [profileData, setProfileData] = useState({});
    const [isTelegramModalOpen, setIsTelegramModalOpen] = useState(false);
    const [isBallanceModalOpen, setIsBallanceModalOpen] = useState(false);
    const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Состояние для загрузки данных

    const navigate = useNavigate();
    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            navigate("/login");
        } else {
            fetchProfileData();
        }
    }, [navigate]);

    const toggleAddProductModal = () => {
        setIsAddProductModalOpen(!isAddProductModalOpen);
    };

    const fetchProfileData = async () => {
        setIsLoading(true); // Устанавливаем состояние загрузки перед запросом данных
        try {
            const response = await api.get('/users/profile');
            setProfileData(response.data);
        } catch (error) {
            console.error("Error fetching profile data:", error);
        } finally {
            setIsLoading(false); // Отключаем состояние загрузки после получения данных
        }
    };

    useEffect(() => {
        fetchProfileData();
    }, []);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.get("ballance") !== null) {
            setIsBallanceModalOpen(true);
        }
    }, [location.search]);

    const handleCategorySelect = (category) => {
        setSelectedCategories(prevCategories => [...prevCategories, category]);
    };

    const handleAssignFilters = async (employeeId, selectedFilters) => {
        try {
            await api.post('/filters/user-assign', {
                filterId: selectedFilters,
                userId: employeeId
            });
            fetchProfileData();
        } catch (error) {
            console.error("Error assigning filters:", error);
        }
    };

    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        setIsAdmin(profileData.role === 'admin');
    }, [profileData]);

    const toggleTelegramModal = () => {
        setIsTelegramModalOpen(!isTelegramModalOpen);
    };

    const toggleBallanceModal = () => {
        setIsBallanceModalOpen(!isBallanceModalOpen);
    };

    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Spin size="large" tip="Загрузка профиля..." />
            </div>
        );
    }

    return (
        <div className="profile">
            <Helmet>
                <title>Личный Кабинет | Tend.kz</title>
                <meta name="description" content="Настройте свой профиль и уведомления на Tend.kz." />
                <link rel="canonical" href="https://tend.kz/profile" />
                <meta name="robots" content="noindex, nofollow" />
                <meta name="author" content="Tend.kz" />
                <meta name="publisher" content="Tend.kz" />
            </Helmet>
            <div className="profile__title">Личный кабинет</div>
            <ProfileTop
                profile={profileData}
                onTelegramClick={toggleTelegramModal}
                onBallanceClick={toggleBallanceModal}
            />

            <div className="profile__bottom">
                {isAdmin ? <EmpHand
                    emp={emp}
                    setEmp={setEmp}
                    employees={profileData.employeesWithFilters || []}
                    filters={profileData.filtersWithEmployees || []}
                    handleAssignFilters={handleAssignFilters}
                /> : null}
                <FiltHand
                    showFiltersAdd={showFiltersAdd}
                    setShowFiltersAdd={setShowFiltersAdd}
                    filters={profileData.filtersWithEmployees || []}
                    selectedCategories={selectedCategories}
                    handleCategorySelect={handleCategorySelect}
                    fetchProfileData={fetchProfileData}
                    employees={profileData.employeesWithFilters || []}
                />
            </div>
            <PriceList pricelist={profileData.priceList} onAddProductClick={toggleAddProductModal}/>

            {isAddProductModalOpen && (
                <AddProductModal onClose={toggleAddProductModal}/>
            )}

            {isTelegramModalOpen && <Telegram onClose={toggleTelegramModal}/>}
            {isBallanceModalOpen && <Ballance onClose={toggleBallanceModal}/>}
        </div>
    );
};

export default Profile;
