import {Helmet} from "react-helmet";

export const License = () => {
    return (
        <div>
            <Helmet>
                <title>Лицензионное соглашение | Tend.kz</title>
                <meta name="description" content="Ознакомьтесь с договором оферты на Tend.kz." />
                <link rel="canonical" href="https://tend.kz/license" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Tend.kz" />
                <meta name="publisher" content="Tend.kz" />
            </Helmet>
            <h1>
                ЛИЦЕНЗИОННОЕ СОГЛАШЕНИЕ
            </h1>
            <i>

                Настоящее Лицензионное соглашение (далее – Соглашение) является документом, регулирующим правила
                использования информационной системы «Tend» (далее – Информационная система) обозначенного выше,
                физическим или юридическим лицом («Лицензиатом»), обладающим правомерно изготовленным и
                зарегистрированным в ИП «Tend.kz» ИИН 041007550334, являющимся Лицензиаром данной информационной системы,
                и является Офертой.
            </i>
            <h2>1. Термины, используемые в настоящем соглашении:</h2>
            <ul>
                <li><strong>1.1. Лицензиар</strong> – лицо, обладающее правами на интеллектуальную собственность,
                    входящую в состав Информационной системы, включая программы для ЭВМ, её компоненты и любые
                    создаваемые ими данные.
                </li>
                <li><strong>1.2. Лицензиат</strong> – физическое или юридическое лицо, которому предоставляется право
                    использования Информационной системы в соответствии с условиями, указанными в данном Соглашении.
                </li>
                <li><strong>1.3. Информационная система</strong> – аппаратно-программный комплекс, находящийся в
                    собственности Лицензиара и предоставляющий агрегированную информацию из открытых источников на сайте
                    tend.kz. Поддержка и обслуживание системы осуществляется Лицензиаром.
                </li>
                <li><strong>1.4. Оплата за пользование</strong> – денежные средства, выплачиваемые Лицензиатом
                    Лицензиару за право использования функций и результатов работы Информационной системы.
                </li>
                <li><strong>1.5. Тариф</strong> – установленная Лицензиаром стоимость предоставления прав на
                    использование Информационной системы на определённый срок.
                </li>
                <li><strong>1.6. Лицензионное соглашение</strong> – договор между Лицензиаром и Лицензиатом,
                    устанавливающий условия использования Информационной системы, хранения и обработки данных, а также
                    ограничения и правила работы с системой.
                </li>
                <li><strong>1.7. Визуально-ознакомительное использование системы</strong> – доступ к системе с целью
                    ознакомления с её публичным содержимым без необходимости регистрации.
                </li>
                <li><strong>1.8. Бесплатное использование системы</strong> – доступ к Информационной системе без оплаты,
                    предоставляемый на ограниченный срок с ограниченным функционалом.
                </li>
                <li><strong>1.9. Платное использование системы</strong> – доступ к Информационной системе на основе
                    произведённой оплаты.
                </li>
                <li><strong>1.10. Персонализированное лицензионное соглашение</strong> – соглашение, заключаемое между
                    Лицензиаром и Лицензиатом на условиях, отличных от стандартных, но не противоречащих этому
                    Соглашению.
                </li>
                <li><strong>1.11. Пользовательский аккаунт</strong> – уникальный профиль Лицензиата, позволяющий
                    персонализированный доступ к функционалу системы.
                </li>
                <li><strong>1.12. Использование информации</strong> – обработка, модификация, копирование или удаление
                    данных в рамках Информационной системы.
                </li>
            </ul>

            <h2>2. Условия использования</h2>
            <ul>
                <li><strong>2.1.</strong> Прежде чем начать использовать Информационную систему, Лицензиат должен
                    ознакомиться с настоящим Соглашением и Политикой конфиденциальности, принять их условия или
                    немедленно прекратить использование системы.
                </li>
                <li><strong>2.2.</strong> Визуальный доступ к системе предоставляется бесплатно.</li>
                <li><strong>2.3.</strong> Бесплатное использование доступно всем пользователям на срок до 14 дней.</li>
                <li><strong>2.4.</strong> Персонализированное использование предоставляется на основе отдельного
                    соглашения с Лицензиаром.
                </li>
                <li><strong>2.5.</strong> Платный доступ к системе возможен после оплаты выбранного тарифа.</li>
            </ul>

            <h2>3. Права по использованию Информационной системы</h2>
            <ul>
                <li><strong>3.1.</strong> Лицензиатам запрещается:
                    <ul>
                        <li><strong>3.1.1.</strong> Копировать, распространять или изменять элементы Информационной
                            системы для коммерческих либо некоммерческих целей без разрешения правообладателя.
                        </li>
                        <li><strong>3.1.2.</strong> Использовать элементы интерфейса или дизайна системы при разработке
                            других сайтов или для другой коммерческой деятельности.
                        </li>
                        <li><strong>3.1.3.</strong> Передавать права на использование системы третьим лицам любыми
                            способами.
                        </li>
                        <li><strong>3.1.4.</strong> Создавать новые аккаунты, если уже существует зарегистрированный
                            аккаунт, за исключением случаев, одобренных технической поддержкой.
                        </li>
                        <li><strong>3.1.5.</strong> Пытаться получить неконтролируемый доступ к системе или вмешиваться
                            в её работу.
                        </li>
                    </ul>
                </li>
                <li><strong>3.2.</strong> При визуальном ознакомлении с системой Лицензиат имеет право:
                    <ul>
                        <li><strong>3.2.1.1.</strong> Копировать информацию, размещенную на общедоступных страницах.
                        </li>
                        <li><strong>3.2.1.2.</strong> Делать ссылки на систему в других источниках информации.</li>
                        <li><strong>3.2.1.3.</strong> Считывать файл robot.txt.</li>
                        <li><strong>3.2.1.4.</strong> Зарегистрироваться в системе и использовать её функционал в
                            течение 14 дней на бесплатной основе.
                        </li>
                    </ul>
                </li>
                <li><strong>3.3.</strong> При наличии прав на платное или персонализированное использование Лицензиат
                    вправе:
                    <ul>
                        <li><strong>3.3.1.</strong> Использовать результаты работы системы для коммерческой
                            деятельности.
                        </li>
                        <li><strong>3.3.2.</strong> Работать с информацией в рамках персонализированного аккаунта.</li>
                        <li><strong>3.3.3.</strong> Настраивать учетную запись в системе.</li>
                        <li><strong>3.3.4.</strong> Полноценно пользоваться функционалом системы.</li>
                    </ul>
                </li>
            </ul>

            <h2>4. Обязанности Лицензиата:</h2>
            <ul>
                <li><strong>4.1.</strong> Соблюдать все условия настоящего Соглашения.</li>
                <li><strong>4.2.</strong> Указывать достоверные данные при регистрации.</li>
                <li><strong>4.3.</strong> Не совершать действия, нарушающие конфиденциальность, целостность и
                    доступность данных системы.
                </li>
                <li><strong>4.4.</strong> Не нарушать интеллектуальные права Лицензиара.</li>
                <li><strong>4.5.</strong> Самостоятельно отслеживать изменения в настоящем Соглашении.</li>
                <li><strong>4.6.</strong> Прекратить использование системы, если условия соглашения изменились, и
                    Лицензиат с ними не согласен.
                </li>
            </ul>

            <h2>5. Права Лицензиара:</h2>
            <ul>
                <li><strong>5.1.</strong> Изменять функционал, контент и структуру системы по своему усмотрению, с
                    уведомлением Лицензиата или без него.
                </li>
                <li><strong>5.2.</strong> Блокировать доступ в случае отзыва согласия на обработку персональных данных.
                </li>
                <li><strong>5.3.</strong> Ограничивать доступ к системе при отсутствии оплаты.</li>
                <li><strong>5.4.</strong> Предоставлять доступ к данным Лицензиата в рамках данного Соглашения.</li>
                <li><strong>5.5.</strong> Напоминать Лицензиату об истечении срока оплаты тарифа.</li>
                <li><strong>5.6.</strong> Принимать меры для защиты своих интеллектуальных прав.</li>
                <li><strong>5.7.</strong> Собирать и обрабатывать данные, связанные с работой системы.</li>
                <li><strong>5.8.</strong> Вносить изменения в Соглашение без предварительного согласования с
                    Лицензиатом.
                </li>
                <li><strong>5.9.</strong> Проводить техническое обслуживание с 23:00 до 7:00 (GMT+6).</li>
            </ul>

            <h2>6. Обязанности Лицензиара:</h2>
            <ul>
                <li><strong>6.1.</strong> Обеспечить доступ к системе согласно тарифу.</li>
                <li><strong>6.2.</strong> Гарантировать работу системы не менее 12 часов в сутки.</li>
            </ul>

            <h2>7. Тарификация:</h2>
            <ul>
                <li><strong>7.1.</strong> Оплата за пользование осуществляется по установленному тарифу.</li>
                <li><strong>7.2.</strong> Первые 14 дней после регистрации предоставляется бесплатный доступ, который
                    затем прекращается без оплаты.
                </li>
                <li><strong>7.3.</strong> Стоимость пользования системой составляет 10 000 тенге.</li>
                <li><strong>7.4.</strong> При оплате за год предоставляется доступ по цене 100 000 тенге.</li>
            </ul>

            <h2>8. Гарантии и ответственность:</h2>
            <ul>
                <li><strong>8.1.</strong> Лицензиар гарантирует доступ к системе в рамках тарифа.</li>
                <li><strong>8.2.</strong> Лицензиар обязуется защищать конфиденциальные данные Лицензиата.</li>
                <li><strong>8.3.</strong> Лицензиар не несёт ответственности за убытки или вред, вызванные
                    использованием системы, её контента или сторонних материалов.
                </li>
                <li><strong>8.4.</strong> Лицензиар не несет ответственности за потерю доступа Лицензиатом к своему
                    аккаунту.
                </li>
                <li><strong>8.5.</strong> Лицензиар не несет ответственности за неправильное указание данных при
                    регистрации.
                </li>
                <li><strong>8.6.</strong> Лицензиар не отвечает за доступ к Интернету и качество связи у Лицензиата.
                </li>
                <li><strong>8.7.</strong> Лицензиар не компенсирует расходы, связанные с прекращением Соглашения по
                    любым причинам.
                </li>
                <li><strong>8.8.</strong> Лицензиар не несет ответственности за ущерб или упущенную выгоду Лицензиата
                    или третьих лиц, вызванные:
                    <ul>
                        <li><strong>8.8.1.</strong> использованием или невозможностью использования системы;</li>
                        <li><strong>8.8.2.</strong> несанкционированным доступом к информации Лицензиата.</li>
                    </ul>
                </li>
            </ul>
        </div>
    )
}
