import {useEffect, useState} from "react";
import {useLocation, useNavigate} from 'react-router-dom';
import Login from "../components/Auth/Login";
import Register from "../components/Auth/Register";
import {Helmet} from "react-helmet";

export const Auth = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [auth, setAuth] = useState(location.pathname === '/login' ? 'login' : 'register');

    useEffect(() => {
        if (location.pathname === '/login') {
            setAuth('login');
        } else if (location.pathname === '/register') {
            setAuth('register');
        }
    }, [location.pathname]);

    const AuthHandler = () => {
        if (auth === 'login') {
            return (<Login/>);
        } else {
            return (<Register/>);
        }
    }

    return (
        <div className="auth">
            <Helmet>
                <title>Вход | Tend.kz</title>
                <meta name="description" content="Вход на Tend.kz - умный агрегатор тендеров Казахстана." />
                <link rel="canonical" href="https://tend.kz/login" />
                <meta name="robots" content="noindex, nofollow" />
                <meta name="author" content="Tend.kz" />
                <meta name="publisher" content="Tend.kz" />
            </Helmet>
            <div className="auth__header">
                <div className={`auth__header--item ${auth === 'login' ? 'active' : ''}`}
                     onClick={() => navigate('/login')}>Вход
                </div>
                <div className={`auth__header--item ${auth === 'register' ? 'active' : ''}`}
                     onClick={() => navigate('/register')}>Регистрация
                </div>
            </div>
            <div className="auth__container">
                <AuthHandler/>
            </div>
        </div>
    );
}
