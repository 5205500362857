import { useNavigate } from 'react-router-dom';
import FiltersAdd from "../components/Profile/FiltersAdd"; // Импорт компонента для добавления фильтра

const AddFilter = () => {
    const navigate = useNavigate();

    const handleFilterAdded = () => {
        // Перенаправляем на страницу "/tenders" после добавления фильтра
        navigate("/tenders");
    };

    return (
        <div>
            <FiltersAdd setShowFiltersAdd={handleFilterAdded} employees={[]} /> {/* Убедитесь, что передаете необходимые пропсы */}
        </div>
    );
};

export default AddFilter;
