import React, {useState} from "react";
import InputMask from "react-input-mask";
import Swal from "sweetalert2";
import api from "../../api"; // Импортируем настроенный Axios

const EmployeeAdd = ({setEmp}) => {
    const [activeInput, setActiveInput] = useState("");
    const [employeeData, setEmployeeData] = useState({
        phone: ''
    });
    const [loading, setLoading] = useState(false);

    const validate = () => {
        let validationErrors = {};
        if (!employeeData.phone.trim() || employeeData.phone.length < 10) {
            validationErrors.phone = "Введите корректный номер телефона";
        }
        if (Object.keys(validationErrors).length > 0) {
            Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                text: validationErrors.phone,
            });
        }
        return Object.keys(validationErrors).length === 0;
    };

    const handleInputChangeWithMask = (e) => {
        const {name, value} = e.target;
        const cleanedValue = value.replace(/[\s+]/g, '').replace(/\D/g, '');
        setEmployeeData({
            [name]: cleanedValue
        });
    };

    const handleSubmit = async () => {
        if (!validate()) return;

        try {
            setLoading(true);
            await api.post('/users/employee/add', employeeData);
            setLoading(false);
            setEmp(false); // Закрыть форму после успешного добавления
            Swal.fire({
                icon: 'success',
                title: 'Успешно',
                text: 'Сотрудник добавлен!',
            });
        } catch (error) {
            setLoading(false);
            if (error.response && error.response.status === 403) {
                Swal.fire({
                    icon: 'error',
                    title: 'Ошибка',
                    text: 'Ваш тарифный план не позволяет добавлять сотрудников. Обновите тарифный план для продолжения.',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Ошибка',
                    text: 'Произошла ошибка при добавлении сотрудника. Попробуйте еще раз.',
                });
            }
        }
    };

    return (
        <div className="profile__emploee">
            <div className="profile__emploee--top">
                <div className="profile__subtitle">Добавить сотрудника</div>
                <div className="profile__emploee--btn" onClick={() => setEmp(false)}>Закрыть</div>
            </div>
            <div className="profile__emploee--block">
                <div className="profile__emploee--item">
                    <div className={`profile__emploee--name ${activeInput === 'phone' ? 'active' : ''}`}>
                        <InputMask
                            mask="+7 999 999 99 99"
                            name="phone"
                            value={employeeData.phone}
                            onChange={handleInputChangeWithMask}
                            placeholder="Введите номер телефона"
                            onFocus={() => setActiveInput('phone')}
                            onBlur={() => setActiveInput('')}
                        />
                    </div>
                </div>
                <div className="profile__emploee--btn mt24" onClick={handleSubmit} disabled={loading}>
                    {loading ? 'Добавление...' : 'Добавить'}
                </div>
            </div>
        </div>
    );
};

export default EmployeeAdd;
