import {useCallback, useEffect, useRef, useState} from 'react';
import {TendersGrid} from "../components/Tenders/TendersGrid";
import api from '../api';
import {useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import {Empty, Button, Typography, Spin} from 'antd';
import {FilterOutlined} from '@ant-design/icons';

export const Tenders = () => {
    const [filters, setFilters] = useState([]);
    const [activeFilters, setActiveFilters] = useState([]);
    const [tenders, setTenders] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(true);
    const [sortOption, setSortOption] = useState('price_asc');
    const [isDataLoaded, setIsDataLoaded] = useState(false); // Дополнительное состояние для завершения загрузки данных
    const observer = useRef();
    const navigate = useNavigate();
    const { Title, Paragraph } = Typography;

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            navigate("/login");
        }
    }, [navigate]);

    const fetchTenders = useCallback(async (selectedFilters, pageNum = 1) => {
        setLoading(true);
        try {
            const filterIds = selectedFilters.length > 0 ? selectedFilters.join(',') : undefined;

            let sortBy, sortDirection;
            if (sortOption === 'price_asc') {
                sortBy = 'price';
                sortDirection = 'asc';
            } else if (sortOption === 'price_desc') {
                sortBy = 'price';
                sortDirection = 'desc';
            } else if (sortOption === 'margin_desc') {
                sortBy = 'marginality_percentage';
                sortDirection = 'desc';
            } else if (sortOption === 'date_asc') {
                sortBy = 'createdAt';
                sortDirection = 'asc';
            } else if (sortOption === 'date_desc') {
                sortBy = 'createdAt';
                sortDirection = 'desc';
            }

            const response = await api.get('/tenders/role-based', {
                params: {
                    filterIds,
                    page: pageNum,
                    limit: 50,
                    sortBy,
                    sortDirection
                }
            });

            setTenders(prevTenders => pageNum === 1 ? response.data.tenders : [...prevTenders, ...response.data.tenders]);
            setFilters(response.data.filters);
            setTotalPages(response.data.totalPages);
            setIsDataLoaded(true); // Устанавливаем, что данные загружены

        } catch (error) {
            if (error.response && error.response.status === 403) {
                navigate('/plans');
            } else {
                console.error('Error fetching tenders:', error);
            }
        } finally {
            setLoading(false);
        }
    }, [sortOption, navigate]);

    useEffect(() => {
        fetchTenders(activeFilters, page);
    }, [fetchTenders, page, activeFilters, sortOption]);

    const toggleFilter = (filterId) => {
        setActiveFilters(prev =>
            prev.includes(filterId)
                ? prev.filter(id => id !== filterId)
                : [...prev, filterId]
        );
        setPage(1);
    };

    const lastTenderElementRef = useCallback((node) => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && page < totalPages) {
                setPage(prevPage => prevPage + 1);
            }
        });
        if (node) observer.current.observe(node);
    }, [loading, page, totalPages]);

    const handleSortChange = (sortValue) => {
        setSortOption(sortValue);
        setPage(1);
        setTenders([]);
    };

    return (
        <>
            <Helmet>
                <title>Лента тендеров | Tend.kz</title>
                <meta name="description" content="Лента тендеров по фильтрам компании" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Tend.kz" />
                <meta name="publisher" content="Tend.kz" />
            </Helmet>
            {loading && !isDataLoaded ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Spin size="large" tip="Загрузка тендеров..." />
                </div>
            ) : filters.length === 0 ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column', backgroundColor: '#f0f2f5', padding: '20px', textAlign: 'center' }}>
                    <Empty
                        imageStyle={{ height: 160 }}
                        description={
                            <div>
                                <Title level={3} style={{ color: '#1890ff' }}>Добавьте фильтры для поиска тендеров</Title>
                                <Paragraph style={{ fontSize: '16px', maxWidth: '600px', margin: '0 auto' }}>
                                    Настройте фильтры, чтобы увидеть релевантные тендеры по вашим критериям. Вы можете выбрать включевые слова, ценовой диапазон, КАТО и другие параметры.
                                </Paragraph>
                                <Button type="primary" icon={<FilterOutlined />} size="large" style={{ marginTop: '20px' }}                 onClick={() => navigate("/add-filter")}>
                                    Настроить фильтры
                                </Button>
                            </div>
                        }
                    />
                </div>
            ) : (
                <TendersGrid
                    filters={filters}
                    tenders={tenders}
                    toggleFilter={toggleFilter}
                    activeFilters={activeFilters}
                    page={page}
                    totalPages={totalPages}
                    setPage={setPage}
                    loading={loading}
                    lastTenderElementRef={lastTenderElementRef}
                    onSortChange={handleSortChange}
                />
            )}
        </>
    );
};
