import React from 'react';
import {useNavigate} from 'react-router-dom';
import error from '../img/404.svg';
import arrow from '../img/arrow.svg';
import {Helmet} from "react-helmet";

function NotFound() {
    const navigate = useNavigate();

    return (
        <div className='error'>
            <Helmet>
                <title>Страница не найдена | Tend.kz</title>
                <meta name="description" content="Данная страница не найдена." />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Tend.kz" />
                <meta name="publisher" content="Tend.kz" />
            </Helmet>
            <img className='error__img' src={error} alt="404"/>
            <h1 className='error__title'>Ой.. Такого нет :(</h1>
            <p className="error__description">Мы еще не придумали, что можно сделать на этой странице</p>
            <div className="error__btn" onClick={() => navigate(-1)}>
                Идем обратно
                <img src={arrow} alt=""/>
            </div>
        </div>
    );
}

export default NotFound;
