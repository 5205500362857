import React, {useState, useEffect} from "react";
import Kato from './Kato';
import api from "../../api";
import Swal from 'sweetalert2';
import Joyride from 'react-joyride';

const FiltersAdd = ({setShowFiltersAdd, fetchProfileData, employees}) => {
    const [keywords, setKeywords] = useState([]);
    const [keywordInput, setKeywordInput] = useState("");
    const [activeInput, setActiveInput] = useState("");
    const [selectedEmployee, setSelectedEmployee] = useState("");
    const [minPrice, setMinPrice] = useState("");
    const [maxPrice, setMaxPrice] = useState("");
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedParents, setSelectedParents] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errorFields, setErrorFields] = useState({});
    const [tourSteps, setTourSteps] = useState([
        {
            target: ".profile__emploee--role select",
            content: "Выберите сотрудника, которому будет назначен данный фильтр. Это опциональный шаг.",
        },
        {
            target: ".profile__emploee--name.w100 input",
            content: "Введите ключевые слова для фильтрации. Ключевые слова должны быть разделены запятой или добавлены с помощью кнопки 'Добавить'.",
        },
        {
            target: ".profile__emploee--name.df input:nth-child(1)",
            content: "Введите минимальную цену. Это значение не может быть больше максимальной цены.",
        },
        {
            target: ".profile__emploee--name.df div:nth-child(2) input",
            content: "Введите максимальную цену. Это значение должно быть больше минимальной цены.",
        },
        {
            target: "button.profile__emploee--btn:nth-child(1)",
            content: "Выберите категории КАТО. Это обязательно для корректного создания фильтра.",
        },
        {
            target: "div.profile__emploee--btn:nth-child(6)",
            content: "Нажмите, чтобы добавить фильтр после заполнения всех обязательных полей.",
        }

    ]);

    useEffect(() => {
        setTimeout(() => {
            setIsTourOpen(true);
        }, 500); // Добавляем небольшую задержку перед запуском тура
    }, []);

    const [isTourOpen, setIsTourOpen] = useState(false);

    const handleKeywordChange = (e) => setKeywordInput(e.target.value);
    const loadingOn = () => setIsLoading(true);
    const loadingOff = () => setIsLoading(false);

    const handleKeywordKeyPress = (e) => {
        if ((e.key === ',' || e.key === 'Enter') && keywordInput.trim() !== "") {
            const trimmedKeyword = keywordInput.trim().replace(/,$/, '');
            setKeywords([...keywords, trimmedKeyword]);
            setKeywordInput("");
            e.preventDefault();
        }
    };

    const handleAddKeyword = () => {
        if (keywordInput.trim() !== "") {
            const trimmedKeyword = keywordInput.trim().replace(/,$/, '');
            setKeywords([...keywords, trimmedKeyword]);
            setKeywordInput("");
        }
    };

    const handleKeywordClick = (index) => {
        setKeywordInput(keywords[index]);
        setKeywords(keywords.filter((_, i) => i !== index));
    };

    const handleKeywordBlur = () => {
        setKeywordInput(keywordInput.replace(/,$/, ''));
    };

    const handleEmployeeChange = (e) => setSelectedEmployee(e.target.value);

    const handleAddFilter = async () => {
        const errors = {};

        if (!keywords.length) errors.keywords = 'Добавьте ключевые слова!';
        if (!minPrice) errors.minPrice = 'Введите минимальную цену!';
        if (!maxPrice) errors.maxPrice = 'Введите максимальную цену!';
        if (parseFloat(minPrice) > parseFloat(maxPrice)) errors.price = 'Минимальная цена не может быть больше максимальной.';
        if (!selectedCategories.length && !isLoading) errors.categories = 'Выберите КАТО!';
        if (!selectedCategories.length && isLoading) errors.categories = 'Дождитесь загрузки КАТО!';

        setErrorFields(errors);

        if (Object.keys(errors).length > 0) {
            Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: Object.values(errors).map(error => `<div>${error}</div>`).join(''),
                confirmButtonText: 'ОК'
            });
            return;
        }

        try {
            const newFilter = {
                name: `Фильтр ${keywords.join(', ')}`,
                keywords,
                minPrice: parseFloat(minPrice),
                maxPrice: parseFloat(maxPrice),
                kato: selectedCategories.map(cat => cat.code).join(','),
                employeeId: selectedEmployee || null
            };

            if (!isLoading) {
                await api.post('/filters', newFilter);
                setShowFiltersAdd(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Фильтр добавлен',
                    text: 'Фильтр успешно добавлен!',
                    confirmButtonText: 'ОК'
                });
                fetchProfileData();
            }
        } catch (error) {
            if (error.response && error.response.status === 403) {
                Swal.fire({
                    icon: 'error',
                    title: 'Ошибка',
                    text: 'Ваш лимит на создание фильтров исчерпан. Улучшите тариф, или свяжитесь с поддержкой.',
                    confirmButtonText: 'ОК'
                });
            }
        }
    };

    const handleCategorySelect = (selectedParent, selectedAll) => {
        setSelectedParents([...selectedParents, selectedParent]);
        setSelectedCategories([...selectedCategories, ...selectedAll]);
    };

    return (
        <div className="profile__emploee profile__filters">
            <Joyride
                steps={tourSteps}
                continuous
                showSkipButton
                run={isTourOpen}
                styles={{
                    options: {
                        zIndex: 1000,
                    },
                }}
                callback={(data) => {
                    if (data.status === 'finished' || data.status === 'skipped') {
                        setIsTourOpen(false);
                    }
                }}
            />
            <div className="profile__emploee--top">
                <div className="profile__subtitle">Добавить фильтр</div>
                <div className="profile__emploee--btn second" onClick={() => setShowFiltersAdd(false)}>Закрыть</div>
            </div>
            <div className="profile__filters">
                <div className="profile__emploee--block">
                    <div className="profile__emploee--item dacc">
                        <div className="profile__number">
                            <div>Назначить сотрудника</div>
                        </div>
                        <div className={`profile__emploee--role ${activeInput === 'employee' ? 'active' : ''}`}>
                            <select
                                value={selectedEmployee}
                                onChange={handleEmployeeChange}
                                onFocus={() => setActiveInput('employee')}
                                onBlur={() => setActiveInput('')}
                            >
                                <option value="">Никто</option>
                                {employees.map(employee => (
                                    <option key={employee.employeeId} value={employee.employeeId}>{employee.employeeName}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    {keywords.length > 0 && (
                        <div className="profile__keywords">
                            {keywords.map((keyword, index) => (
                                <div className='profile__keywords--item' key={index} onClick={() => handleKeywordClick(index)}>
                                    {keyword}
                                </div>
                            ))}
                        </div>
                    )}
                    <p style={{opacity: .5, padding: '12px 0 0 12px ', color: 'rgb(255, 10, 27)'}}>
                        Для добавления ключевых слов напишите их через запятую, либо нажмите на кнопку добавить после
                        каждой ключевой фразы.
                    </p>
                    <div className="profile__emploee--item">
                        <div className={`profile__emploee--name w100 ${activeInput === 'keywords' ? 'active' : ''}`}>
                            <input
                                type="text"
                                placeholder="Введите ключевые слова через запятую"
                                value={keywordInput}
                                onChange={handleKeywordChange}
                                onKeyPress={handleKeywordKeyPress}
                                onFocus={() => setActiveInput('keywords')}
                                onBlur={() => {
                                    handleKeywordBlur();
                                    setActiveInput('');
                                }}
                                style={{borderColor: errorFields.keywords ? 'red' : undefined}}
                            />
                            <button
                                onClick={handleAddKeyword}
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    backgroundColor: '#35A7EA',
                                    color: 'white',
                                    border: 'none',
                                    borderRadius: '12px',
                                    padding: '5px 20px',
                                    cursor: 'pointer',
                                }}
                            >
                                Добавить
                            </button>
                        </div>
                    </div>
                    <div className="profile__emploee--item">
                        <div className={`profile__emploee--name df ${activeInput === 'minPrice' ? 'active' : ''}`}>
                            <div>
                                <input
                                    type="number"
                                    placeholder="Минимальная цена"
                                    value={minPrice}
                                    onChange={(e) => setMinPrice(e.target.value)}
                                    onFocus={() => setActiveInput('minPrice')}
                                    onBlur={() => setActiveInput('')}
                                    style={{borderColor: errorFields.minPrice || errorFields.price ? 'red' : undefined}}
                                />
                            </div>
                            <div>
                                <input
                                    type="number"
                                    placeholder="Максимальная цена"
                                    value={maxPrice}
                                    onChange={(e) => setMaxPrice(e.target.value)}
                                    onFocus={() => setActiveInput('maxPrice')}
                                    onBlur={() => setActiveInput('')}
                                    style={{borderColor: errorFields.maxPrice || errorFields.price ? 'red' : undefined}}
                                />
                            </div>
                        </div>
                    </div>
                    {selectedParents.length > 0 && (
                        <div className="profile__filters--katos">
                            {selectedParents.map(category => (
                                <div className='profile__filters--kato' key={category.code}>{category.name}</div>
                            ))}
                        </div>
                    )}
                    <Kato onSelect={handleCategorySelect} on={loadingOn} off={loadingOff}/>
                    <div className="profile__emploee--btn mt24" onClick={handleAddFilter}>Добавить фильтр</div>
                </div>
            </div>
        </div>
    );
};

export default FiltersAdd;
