import {Select} from 'antd';

const {Option} = Select;

export const TendersGridTop = ({onSortChange, currentSort}) => {
    const handleSelectChange = (value) => {
        onSortChange(value); // Передаем выбранное значение в родительский компонент
    };
    return (
        <div className="TendersGrid__top">
            <h1>Лента тендеров</h1>
            <Select
                value={currentSort || undefined} // Устанавливаем текущее значение сортировки, или undefined если ничего не выбрано
                className="sort__select"
                style={{width: 200}}
                onChange={handleSelectChange}
                placeholder="Выберите сортировку" // Текст по умолчанию
            >
                <Option value='price_asc'>По возрастанию цены</Option>
                <Option value='price_desc'>По убыванию цены</Option>
                <Option value='margin_desc'>По маржинальности</Option>
                <Option value='date_asc'>По дате (возрастание)</Option>
                <Option value='date_desc'>По дате (убывание)</Option>
            </Select>
        </div>
    );
};
