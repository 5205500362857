import React, { useState, useEffect } from "react";
import { Modal, Button, Typography, Tour } from "antd";
import Profile from "./Profile"; // Ваш компонент профиля

const { Title, Paragraph } = Typography;

const ProfileWithGuide = () => {
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [isTourOpen, setIsTourOpen] = useState(false);
    const [steps, setSteps] = useState([]);

    useEffect(() => {
        const profileTitle = document.querySelector(
            "html body div#root div.container div div.profile div.profile__title"
        );
        const filterButton = document.querySelector(
            "html body div#root div.container div div.profile div.profile__bottom div.profile__emploee.profile__filters div.profile__emploee--top.d-flex.justify-content-between.align-items-center.mb-3 button.ant-btn.css-dev-only-do-not-override-apn68.ant-btn-primary.ant-btn-color-primary.ant-btn-variant-solid.profile__emploee--btn.second"
        );

        setSteps([
            {
                title: "Добро пожаловать!",
                description: "Это ваш профиль. Здесь вы можете управлять настройками и информацией.",
                target: profileTitle,
                placement: "bottom",
            },
            {
                title: "Добавление фильтра",
                description: "Нажмите эту кнопку, чтобы добавить новый фильтр и настроить поиск.",
                target: filterButton,
                placement: "bottom",
            },
        ]);
    }, []);

    const handleModalClose = () => {
        setIsModalOpen(false);
        setTimeout(() => setIsTourOpen(true), 100); // Отложенный запуск тура
    };

    return (
        <div>
            <Modal
                title={<Title level={3}>Добро пожаловать в вашу систему управления тендерами!</Title>}
                open={isModalOpen}
                onCancel={handleModalClose}
                centered
                footer={[
                    <Button key="start" type="primary" onClick={handleModalClose} style={{ fontSize: "16px", padding: "8px 16px" }}>
                        Начать тур
                    </Button>,
                ]}
                contentStyle={{
                    padding: "20px 30px",
                    borderRadius: "12px",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                    maxWidth: "500px",
                    margin: "0 auto"
                }}
            >
                <Paragraph style={{ fontSize: "16px", lineHeight: "1.6" }}>
                    Мы рады видеть вас в системе! Здесь вы сможете легко находить интересные тендеры,
                    добавлять фильтры для точного поиска и вести аналитику.
                </Paragraph>
                <Paragraph style={{ fontSize: "16px", lineHeight: "1.6" }}>
                    Перейдите к ленте тендеров и откройте для себя подходящие проекты. Для доступа
                    к полному функционалу выберите тариф, который подходит именно вам!
                </Paragraph>
            </Modal>

            {steps.length > 0 && (
                <Tour open={isTourOpen} onClose={() => setIsTourOpen(false)} steps={steps} />
            )}
            <Profile />
        </div>
    );
};

export default ProfileWithGuide;
