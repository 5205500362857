import loginIcon from '../../img/login.svg';
import regIcon from '../../img/reg.svg';
import {Link, useNavigate} from "react-router-dom";
import React, {useContext, useEffect, useRef, useState} from "react";
import {AuthContext} from '../../contexts/AuthContext';
import {Turn as Hamburger} from 'hamburger-react';

const AuthLinks = ({id, isFooter}) => {
    const {isAuthenticated, logout} = useContext(AuthContext);
    const navigate = useNavigate();
    const [isOpen, setOpen] = useState(false);
    const menuRef = useRef(null); // Ref для мобильного меню
    const hamburgerRef = useRef(null); // Ref для кнопки гамбургера

    const handleLogout = () => {
        logout();
        navigate('/');
        setOpen(false); // Закрыть меню после выхода
    };

    const handleMenuClick = (path) => {
        navigate(path);
        setOpen(false); // Закрыть меню после клика на пункт меню
    };

    // Закрытие меню при клике вне его
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                menuRef.current &&
                !menuRef.current.contains(event.target) &&
                hamburgerRef.current &&
                !hamburgerRef.current.contains(event.target)
            ) {
                setOpen(false); // Закрыть меню, если клик был вне него и вне кнопки гамбургера
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    const iMenu = () => isOpen && (
        <div className="mobileMenu" ref={menuRef}>
            <div className="mobileMenu__header">
                <div onClick={() => handleMenuClick('/')}>
                    Главная
                </div>

                <div onClick={() => handleMenuClick('/profile')}>
                    Личный кабинет
                </div>
                <div onClick={() => handleMenuClick('/tenders')}>
                    Лента тендеров
                </div>
                <div onClick={() => handleMenuClick('/plans')}>
                    Тарифные планы
                </div>
            </div>

            <div className="mobileMenu__footer">
                <div className="authLink__logout" onClick={handleLogout}>
                    Выйти
                </div>
            </div>
        </div>
    );

    return (
        <>
            <div className="authLink">
                {isAuthenticated ? (
                    <>
                        <div className="authLink__profile" onClick={() => handleMenuClick('/profile')}>
                            Профиль
                        </div>
                        <div className="authLink__logout dnm" onClick={handleLogout}>
                            Выйти
                        </div>
                        {/* Гамбургер рендерится только если компонент не внутри Footer */}
                        {!isFooter && (
                            <div ref={hamburgerRef}>
                                <Hamburger size={20} toggle={setOpen} toggled={isOpen} />
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <Link to='/login' className="authLink__login">
                            Войти
                            <img src={loginIcon} alt=""/>
                        </Link>
                        <Link to='/register' className="authLink__reg">
                            Регистрация
                            <img src={regIcon} alt=""/>
                        </Link>
                    </>
                )}
                {iMenu()}
            </div>
        </>
    );
};

export default AuthLinks;
