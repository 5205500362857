import React, {useEffect, useState} from "react";
import api from "../api";
import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";

const Plans = () => {
    const [period, setPeriod] = useState('year');
    const [activePlan, setActivePlan] = useState(null);
    const [expired, setExpired] = useState(false);
    const navigate = useNavigate();

    const plan = {
        month: [
            {
                id: 1,
                title: "Пробный",
                price: "0 ",
                description: "Бесплатный доступ агрегатора на 14 дней\nДоступ ко всем функциям\nОбучение работе с платформой"
            },
            {
                id: 2,
                title: "Старт",
                price: "9 000 ",
                description: "Доступ к базе тендеров \n Доступ к ИИ аналитике тендеров \nИндивидуальная email рассылка \n Индивидуальная Telegram рассылка \n Индивидуальная WhatsApp рассылка \nЛимит фильтров - 3 \n Служба поддержки 24/7 "
            },
            {
                id: 3,
                title: "Бизнес",
                price: "12 000 ",
                description: "Доступ к базе тендеров \n Полный доступ к ИИ аналитике тендеров \n Юридические рекомендации к каждому тендеру \n Аналитика документации к каждому тендеру \n Прогнозирование маржинальности \n Аналитика оптимальной ставки \n Аналитика похожих тендеров \n  Сортировка по маржинальности \n Поиск тендеров по прайс листу \n Регистрация сотрудников \n Лимит сотрудников - 3 \n Индивидуальная email рассылка \n Индивидуальная Telegram рассылка \n Индивидуальная WhatsApp рассылка \nЛимит фильтров - 10 \n Служба поддержки 24/7 \n Личный менеджер"
            },
            {
                id: 4,
                title: "Премиум",
                price: "18 000 ",
                description: "Доступ к базе тендеров \n Полный доступ к ИИ аналитике тендеров \n Юридические рекомендации к каждому тендеру \n Аналитика документации к каждому тендеру \n Персонализированные отчеты по тендерам \n Прогнозы на основе отраслевых данных \n Выгрузка тендеров в excel \n Прогнозирование маржинальности \n Аналитика оптимальной ставки \n Аналитика похожих тендеров \n  Сортировка по маржинальности \n Поиск тендеров по прайс листу \n Регистрация сотрудников \n Лимит сотрудников - 3 \n Индивидуальная email рассылка \n Индивидуальная Telegram рассылка \n Индивидуальная WhatsApp рассылка \nЛимит фильтров - 15 \n Служба поддержки 24/7 \n Личный менеджер"
            }
        ],
        year: [
            {
                id: 4,
                title: "Пробный",
                price: "0 ",
                description: "Бесплатный доступ агрегатора на 14 дней\nДоступ ко всем функциям\nОбучение работе с платформой"
            },
            {
                id: 3,
                title: "Старт",
                price: "75 000 ",
                description: "Доступ к базе тендеров \n Доступ к ИИ аналитике тендеров \nИндивидуальная email рассылка \n Индивидуальная Telegram рассылка \n Индивидуальная WhatsApp рассылка \nЛимит фильтров - 3 \n Служба поддержки 24/7 "
            },
            {
                id: 2,
                title: "Бизнес",
                price: "100 000 ",
                description: "Доступ к базе тендеров \n Полный доступ к ИИ аналитике тендеров \n Юридические рекомендации к каждому тендеру \n Аналитика документации к каждому тендеру \n Прогнозирование маржинальности \n Аналитика оптимальной ставки \n Аналитика похожих тендеров \n  Сортировка по маржинальности \n Поиск тендеров по прайс листу \n Регистрация сотрудников \n Лимит сотрудников - 3 \n Индивидуальная email рассылка \n Индивидуальная Telegram рассылка \n Индивидуальная WhatsApp рассылка \nЛимит фильтров - 10 \n Служба поддержки 24/7 \n Личный менеджер"
            },
            {
                id: 1,
                title: "Премиум",
                price: "150 000 ",
                description: "Доступ к базе тендеров \n Полный доступ к ИИ аналитике тендеров \n Юридические рекомендации к каждому тендеру \n Аналитика документации к каждому тендеру \n Персонализированные отчеты по тендерам \n Прогнозы на основе отраслевых данных \n Выгрузка тендеров в excel \n Прогнозирование маржинальности \n Аналитика оптимальной ставки \n Аналитика похожих тендеров \n  Сортировка по маржинальности \n Поиск тендеров по прайс листу \n Регистрация сотрудников \n Лимит сотрудников - 3 \n Индивидуальная email рассылка \n Индивидуальная Telegram рассылка \n Индивидуальная WhatsApp рассылка \nЛимит фильтров - 15 \n Служба поддержки 24/7 \n Личный менеджер"
            }
        ]
    };

    const fetchPlan = async () => {
        try {
            const response = await api.get('/users/plan');
            const {planName, planExpiration} = response.data;
            setActivePlan(planName);
            const expirationDate = new Date(planExpiration);
            const currentDate = new Date();
            if (expirationDate < currentDate) {
                setExpired(true);
                Swal.fire({
                    icon: 'warning',
                    title: 'Тариф истек!',
                    text: 'Ваш тарифный план истек. Пожалуйста, выберите новый план.',
                    confirmButtonText: 'ОК'
                });
            }
        } catch (error) {
            console.error("Error fetching plan:", error);
        }
    };

    useEffect(() => {
        fetchPlan();
    }, []);

    const assignPlan = async (planId) => {
        try {
            const response = await api.put('/plans/assign', {
                "planId": planId,
                "duration": "monthly"
            });

            // Если план был успешно изменен
            if (response.data.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Успешно!',
                    text: 'План активирован!',
                    confirmButtonText: 'ОК'
                });

                navigate('/profile');
            } else {
                // Если план не изменился, выводим сообщение из ответа
                Swal.fire({
                    icon: 'info',
                    title: 'Внимание!',
                    text: response.data.message || 'План не был изменен.',
                    confirmButtonText: 'ОК'
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Внимание!',
                text: 'Ошибка. Возможно, на балансе недостаточно средств',
                showCancelButton: true,
                confirmButtonText: 'Попробовать еще',
                cancelButtonText: 'Пополнить баланс'
            }).then((result) => {
                if (result.isConfirmed) {
                    assignPlan(planId);  // Попробовать еще
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    navigate('/profile?ballance');  // Пополнить баланс
                }
            });
        }
    };

    return (
        <section className="plans plansPage">
            <Helmet>
                <title>Тарифы | Tend.kz</title>
                <meta name="description" content="Просмотрите доступные тарифы на Tend.kz." />
                <link rel="canonical" href="https://tend.kz/plans" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Tend.kz" />
                <meta name="publisher" content="Tend.kz" />
            </Helmet>
            <div className="plans__title">Тарифы</div>
            <div className="plans__header">
                <div
                    className={`plans__header--item ${period === 'month' ? 'active' : ''}`}
                    onClick={() => setPeriod('month')}
                >
                    Месяц
                </div>
                <div
                    className={`plans__header--item ${period === 'year' ? 'active' : ''}`}
                    onClick={() => setPeriod('year')}
                >
                    Год
                </div>
            </div>
            <div className="plans__block">
                {plan[period].map((item) => (
                    <div key={item.id} className="plans__item">
                        <div className="plans__item--block">
                            <div className="plans__item--top">
                                <div className="plans__item--title">
                                    {item.title} {activePlan === item.title && !expired && <span>(Активный)</span>}
                                </div>
                                <div className="plans__item--price">{item.price}₸</div>
                            </div>
                            <div className="plans__item--text">
                                {item.description.split('\n').map((line, index) => (
                                    <p key={index}>{line}<br/></p>
                                ))}
                            </div>
                            {item.title !== "Пробный" ? (
                                <button onClick={() => assignPlan(item.id)}
                                        disabled={activePlan === item.title && !expired}>
                                    {activePlan === item.title && !expired ? 'Активен' : 'Применить тариф'}
                                </button>
                            ) : (
                                <button disabled='disabled'>Применить тариф</button>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Plans;
