import arrow from '../../img/arrow__btn.svg';
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';

const Login = () => {
    const [activeInput, setActiveInput] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { login } = useContext(AuthContext);


    const handleLogin = async () => {
        try {
            const response = await fetch('https://tend.kz/api/users/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, password })
            });

            const data = await response.json();
            if (response.ok) {
                login(data.token);
                navigate('/profile');
            } else {
                alert('Ошибка при входе: ' + data.message);
            }
        } catch (error) {
            console.error('Ошибка:', error);
            alert('Ошибка при соединении с сервером.');
        }
    };

    return (
        <div className="login">
            <div className="auth__title">
                Авторизация
            </div>
            <div className="auth__form">
                <div className={`auth__input--wrapper ${activeInput === 'email' ? 'active' : ''}`}>
                    <input
                        type="email"
                        className="auth__input"
                        placeholder='Email'
                        value={email}
                        onFocus={() => setActiveInput('email')}
                        onBlur={() => setActiveInput('')}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {activeInput === 'email' && <div className="placeholder">Email</div>}
                </div>
                <div className={`auth__input--wrapper ${activeInput === 'password' ? 'active' : ''}`}>
                    <input
                        type="password"
                        className="auth__input"
                        placeholder='Пароль'
                        value={password}
                        onFocus={() => setActiveInput('password')}
                        onBlur={() => setActiveInput('')}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {activeInput === 'password' && <div className="placeholder">Пароль</div>}
                </div>
            </div>
            <div className="auth__sub">
                <div className="auth__btn" onClick={handleLogin}>
                    Войти <img src={arrow} alt=""/>
                </div>
                <div className="auth__reset">
                    Забыли пароль?
                </div>
            </div>
        </div>
    );
};

export default Login;
