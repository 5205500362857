import samplePdf from './../samle.pdf';
import {Helmet} from "react-helmet";

const PdfViewer = () => {
    return (
        <div>
            <Helmet>
                <title>Договор Оферты | Tend.kz</title>
                <meta name="description" content="Ознакомьтесь с договором оферты на Tend.kz." />
                <link rel="canonical" href="https://tend.kz/oferta" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Tend.kz" />
                <meta name="publisher" content="Tend.kz" />
            </Helmet>
            <iframe
                src={samplePdf}
                title="PDF Viewer"
                width="100%"

                height="700px"
                style={{border: 'none'}}
            />
        </div>
    );
};

export default PdfViewer;
