import React from "react";
import Filters from "./Filters";
import FiltersAdd from "./FiltersAdd";

const FiltHand = ({
                      showFiltersAdd,
                      fetchProfileData,
                      setShowFiltersAdd,
                      filters,
                      selectedCategories,
                      handleCategorySelect,
                      employees
                  }) => {
    if (showFiltersAdd) {
        return (
            <FiltersAdd
                selectedCategories={selectedCategories}
                onCategorySelect={handleCategorySelect}
                setShowFiltersAdd={setShowFiltersAdd}
                employees={employees}
                fetchProfileData={fetchProfileData}
            />
        );
    } else {
        return (
            <Filters
                filters={filters}
                setShowFiltersAdd={setShowFiltersAdd}
            />
        );
    }
};

export default FiltHand;
