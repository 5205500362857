import React, { useState } from "react";
import { Table, Button, Card, Col, Row, Empty } from 'antd';
import { useMediaQuery } from 'react-responsive'; // Для адаптивной проверки

const PriceList = ({ pricelist, onAddProductClick }) => {
    const [visibleItems, setVisibleItems] = useState(10); // Количество видимых элементов
    const isMobile = useMediaQuery({ maxWidth: 768 }); // Проверяем, если экран меньше 768px

    if (!pricelist || !pricelist.items) {
        return <Empty description="Прайслист недоступен" />;
    }

    const filteredItems = pricelist.items.filter(item => {
        return !(item.name === '' && item.price === '');
    });

    const showMoreItems = () => {
        setVisibleItems((prev) => prev + 10); // Увеличиваем количество видимых элементов на 10
    };

    const columns = [
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            render: (text) => <strong>{text}</strong>,
        },
        {
            title: 'Цена',
            dataIndex: 'price',
            key: 'price',
            align: 'center',
            render: (text) => <span style={{ color: '#3f8600', fontWeight: 'bold' }}>{text} тг</span>,
        },
        {
            title: 'Ключевые слова',
            dataIndex: 'keywords',
            key: 'keywords',
            align: 'center',
            render: (keywords) => (
                <span style={{ color: '#108ee9' }}>{keywords ? keywords.join(', ') : 'Нет ключевых слов'}</span>
            ),
        },
    ];

    return (
        <div className="profile__emploee" style={{ width: '100%' }}>
            <div className="profile__emploee--top d-flex justify-content-between align-items-center mb-3">
                <div className="profile__subtitle">Прайслист</div>
                <Button className="profile__emploee--btn" type="primary" onClick={onAddProductClick} shape="round">
                    Добавить товары
                </Button>
            </div>
            {filteredItems.length > 0 ? (
                <>
                    {isMobile ? (
                        // Отображение карточек на мобильных устройствах
                        <Row gutter={[16, 16]}>
                            {filteredItems.slice(0, visibleItems).map((item) => (
                                <Col xs={24} sm={24} key={item.id}>
                                    <Card
                                        title={item.name}
                                        bordered={false}
                                    >
                                        <p><strong>Цена:</strong> {`${item.price} тг`}</p>
                                        <p><strong>Ключевые слова:</strong> {item.keywords ? item.keywords.join(', ') : 'Нет ключевых слов'}</p>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        // Обычная таблица для десктопных экранов
                        <Table
                            dataSource={filteredItems.slice(0, visibleItems)}
                            columns={columns}
                            rowClassName={(record, index) => (index % 2 === 0 ? 'row-blue' : 'row-white')}
                            rowKey="id"
                            pagination={false} // Отключаем встроенную пагинацию
                            style={{ background: '#f0f2f5', borderRadius: '8px' }}
                        />
                    )}

                    {visibleItems < filteredItems.length && (
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            <Button type="default" onClick={showMoreItems} shape="round">
                                Показать еще
                            </Button>
                        </div>
                    )}
                </>
            ) : (
                <Empty description="Товары отсутствуют" />
            )}
        </div>
    );
};

export default PriceList;
