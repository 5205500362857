import arrow from '../img/arrow__back.svg';
import statusActive from "../img/status__active.svg";
import star from "../img/star.svg";
import note from "../img/note.svg";
import share from "../img/share.svg";
import {useEffect, useState} from "react";
import {File} from '../components/Tender/File';
import {TenderData} from '../components/Tender/TenderData';
import api from '../api';
import {useNavigate, useParams} from "react-router-dom";
import "../styles/tendermobile.scss"
import {Helmet} from "react-helmet";

export const Tender = () => {
    const navigate = useNavigate();
    const [isFavorite, setIsFavorite] = useState(false);
    const [tenderData, setTenderData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const {tenderId} = useParams();

    const handleFavoriteClick = () => {
        setIsFavorite(!isFavorite);
    };


    const handleGoBack = () => {
        navigate(-1);
    };

    // Загружаем данные тендера с сервера
    useEffect(() => {
        const fetchTenderData = async () => {
            try {
                const response = await api.get(`/tenders/${tenderId}`);
                setTenderData(response.data);
            } catch (err) {
                setError('Ошибка при загрузке данных тендера');
            } finally {
                setLoading(false);
            }
        };

        fetchTenderData();
    }, [tenderId]);

    if (loading) {
        return <div>Загрузка...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }
    const {main_lot, announce} = tenderData;
    main_lot.planned_total_amount = undefined;

    const productsModels = Array.isArray(main_lot.extended_info.products_models)
        ? main_lot.extended_info.products_models.join(", ")
        : main_lot.extended_info.products_models;

    return (
        <div className="tenderPage">
            <Helmet>
                <title>{announce.name} | Tend.kz</title>
                <meta name="description" content={main_lot.additional_description} />
                <link rel="canonical" href={`https://tend.kz/tender/${main_lot.id}`} />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Tend.kz" />
                <meta name="publisher" content="Tend.kz" />
            </Helmet>
            <div className="tenderPage__top">
                <div className="tenderPage__back" onClick={handleGoBack}>
                    <img src={arrow} alt=""/>
                </div>
                <div className="tenderPage__titlePage">{announce.name}</div>
                {/* Заголовок тендера */}
            </div>
            <div className="tenderPage__block">
                <div className="tenderPage__name">
                    {main_lot.ktru_name} {/* Название лота */}
                </div>
                <div className="tender__bottom">
                    <div className="tender__bottom--left">
                        <img src={statusActive} alt=""/>
                        <span>{announce.status}</span> {/* Статус тендера */}
                    </div>
                    <div className="tender__bottom--right">
                        <div className={`tender__btn ${isFavorite ? 'favorite' : ''}`} onClick={handleFavoriteClick}>
                            В избранное
                            <img src={star} alt=""/>
                        </div>
                        <div className="tender__btn">
                            Добавить заметку
                            <img src={note} alt=""/>
                        </div>
                        <div className="tender__btn">
                            Поделиться
                            <img src={share} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="tenderPage__info">
                    <div className="tenderPage__info--left">
                        <TenderData title="ID лота" content={main_lot.id}/>
                        <TenderData title="КТРУ код" content={main_lot.ktru_code}/>
                        <TenderData title="Наименование товара" content={main_lot.ktru_name}/>
                        <TenderData title="Количество" content={main_lot.quantity}/>
                        <TenderData title="Цена за единицу" content={`${main_lot.unit_price} ₸`}/>
                        <TenderData title="Единица измерения" content={main_lot.unit_measurement}/>
                        <TenderData title="Общая сумма" content={main_lot.planned_total_amount}/>
                        <TenderData title="Дата начала" content={main_lot.start_date}/>
                        <TenderData title="Дата окончания" content={main_lot.end_date}/>
                        <TenderData title="Адрес доставки" content={main_lot.delivery_location}/>
                        <TenderData title="Краткое описание" content={main_lot.brief_description}/>
                        <TenderData title="Дополнительное описание" content={main_lot.additional_description}/>
                        <TenderData title="Источник финансирования" content={main_lot.funding_source}/>
                        <TenderData title="Номер лота" content={main_lot.extended_info.lot_number}/>
                        <TenderData title="Себестоимость" content={`${main_lot.extended_info.cost_price} ₸`}/>
                        <TenderData title="Оптимальная ставка" content={`${main_lot.extended_info.optimal_rate} ₸`}/>
                        <TenderData title="Маржинальность" content={`${main_lot.extended_info.marginality_procent}%`}/>
                        <TenderData title="Прибыль" content={`${main_lot.extended_info.marginality_price} ₸`}/>
                        <TenderData title="Продукты" content={main_lot.extended_info.products}/>
                        <TenderData title="Модели продуктов" content={productsModels}/>
                        <TenderData title="Название" content={announce.name}/>
                        <TenderData title="Тип закупки" content={announce.purchase_type}/>
                        <TenderData title="Метод закупки" content={announce.purchase_method}/>
                        <TenderData title="Полная цена" content={`${announce.full_price} ₸`}/>
                        <TenderData title="Организатор" content={announce.organizer_name}/>
                        <TenderData title="Юридический адрес" content={announce.legal_address}/>
                        <TenderData title="Телефон организатора" content={announce.organizer_phone}/>
                        <TenderData title="Email организатора" content={announce.organizer_email}/>
                        <TenderData title="Дата начала приема заявок" content={announce.offers_start_date}/>
                        <TenderData title="Дата окончания приема заявок" content={announce.offers_end_date}/>
                    </div>
                    <div className="tenderPage__dock">
                        <div className="tenderPage__btns">
                            <div className="tenderPage__btn tenderPage__btnr">
                                <a className="tenderPage__btn--text" href={announce.url} target="_blank" rel="noreferrer">Перейти к
                                    тендеру</a>
                            </div>
                        </div>
                        <div className="tenderPage__dock--title">
                            Документация
                        </div>
                        <div className="tenderPage__dock--block">

                            {main_lot.spec_url && main_lot.spec_url.length > 0 ? (
                                main_lot.spec_url.map((url, index) => (
                                    <File url={url} key={index}/>
                                ))
                            ) : (
                                <p>Нет доступных файлов</p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="tenderPage__btns">
                    <div className="tenderPage__btn">
                        <div className="tenderPage__btn--text">Оптимальная ставка
                            - {main_lot.extended_info.optimal_rate}₸
                        </div>

                    </div>
                    <div className="tenderPage__btn">
                        <div className="tenderPage__btn--text">Маржинальность
                            - {main_lot.extended_info.marginality_procent}%
                        </div>

                    </div>
                    <div className="tenderPage__btn">
                        <div className="tenderPage__btn--text">Прибыль - {main_lot.extended_info.marginality_price}₸
                        </div>

                    </div>
                </div>

                <div className="tenderPage__desc">
                    <div className="tenderPage__desc--title">Общие рекомендации</div>
                    <div className="tenderPage__desc--text">{main_lot.extended_info.general_recommendation}</div>
                </div>

                <div className="tenderPage__desc">
                    <div className="tenderPage__desc--title">Юридические рекомендации</div>
                    <div className="tenderPage__desc--text">{main_lot.extended_info.legal_recommendations}</div>
                </div>

                <div className="tenderPage__desc">
                    <div className="tenderPage__desc--title">Технические рекомендации</div>
                    <div className="tenderPage__desc--text">
                        {Object.entries(JSON.parse(main_lot.extended_info.files)).map(([fileName, fileInfo]) => (
                            <div key={fileName}>
                                <strong>{fileName}:</strong> {fileInfo}
                            </div>
                        ))}
                    </div>

                </div>
                <div className="tenderPage__desc">
                    <div className="tenderPage__desc--title">Описание лота</div>
                    <div className="tenderPage__desc--text">{main_lot.additional_description}</div>
                </div>
                <div className="tenderPage__btna">
                    <div className="tenderPage__btn">

                        <a className="tenderPage__btn--text" href={announce.url} target="_blank" rel="noreferrer">Перейти к тендеру</a>
                    </div>
                </div>

            </div>
        </div>
    );
};
