import React, { useContext, useState } from "react";
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import Swal from 'sweetalert2';
import { Tooltip } from 'antd';
import arrow from "../../img/register.svg";

const Register = () => {
    const [role, setRole] = useState('admin');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [phone, setPhone] = useState('');
    const [errors, setErrors] = useState({});
    const [tooltipVisible, setTooltipVisible] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { login } = useContext(AuthContext);

    const validate = () => {
        let validationErrors = {};

        if (!firstName.trim() || firstName.length < 2) {
            validationErrors.firstName = 'Имя должно быть не менее 2 символов';
            setTooltipVisible('firstName');
        } else if (!lastName.trim() || lastName.length < 2) {
            validationErrors.lastName = 'Фамилия должна быть не менее 2 символов';
            setTooltipVisible('lastName');
        } else if (role === 'admin' && (!companyName.trim() || companyName.length < 2)) {
            validationErrors.companyName = 'Название компании должно быть не менее 2 символов';
            setTooltipVisible('companyName');
        } else if (!email.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            validationErrors.email = 'Введите корректный email';
            setTooltipVisible('email');
        } else if (!phone.trim() || phone.replace(/\D/g, '').length < 10) {
            validationErrors.phone = 'Введите корректный номер телефона';
            setTooltipVisible('phone');
        } else if (!password || password.length < 8) {
            validationErrors.password = 'Пароль должен содержать минимум 8 символов';
            setTooltipVisible('password');
        } else if (password !== repeatPassword) {
            validationErrors.repeatPassword = 'Пароли не совпадают';
            setTooltipVisible('repeatPassword');
        }

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleInputChange = (e, field) => {
        const value = e.target.value;
        switch (field) {
            case 'firstName':
                setFirstName(value);
                break;
            case 'lastName':
                setLastName(value);
                break;
            case 'companyName':
                setCompanyName(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'phone':
                setPhone(value.replace(/\D/g, ''));
                break;
            case 'password':
                setPassword(value);
                break;
            case 'repeatPassword':
                setRepeatPassword(value);
                break;
            default:
                break;
        }
        setErrors((prevErrors) => ({ ...prevErrors, [field]: '' }));
        if (tooltipVisible === field) setTooltipVisible(null);
    };

    const handleRegister = async () => {
        if (!validate()) return;

        setLoading(true);

        try {
            const response = await fetch('https://tend.kz/api/users/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ firstName, lastName, email, password, phone, role, companyName })
            });

            const data = await response.json();
            if (response.ok) {
                login(data.token);
                navigate('/profile/guide');
            } else {
                Swal.fire('Ошибка при регистрации', data.message, 'error');
            }
        } catch (error) {
            console.error('Ошибка:', error);
            Swal.fire('Ошибка', 'Ошибка при соединении с сервером', 'error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="login">
            <div className="auth__title">Регистрация</div>
            <div className="login__header">
                <div className={`login__header--item ${role === 'employee' ? 'active' : ''}`}
                     onClick={() => setRole('employee')}>Сотрудник
                </div>
                <div className={`login__header--item ${role === 'admin' ? 'active' : ''}`}
                     onClick={() => setRole('admin')}>Администратор
                </div>
            </div>
            <div className="auth__form">
                <Tooltip title={errors.firstName} visible={tooltipVisible === 'firstName'} placement="right">
                    <div className="auth__input--wrapper">
                        <input
                            type="text"
                            className="auth__input"
                            placeholder="Имя"
                            value={firstName}
                            onChange={(e) => handleInputChange(e, 'firstName')}
                        />
                    </div>
                </Tooltip>
                <Tooltip title={errors.lastName} visible={tooltipVisible === 'lastName'} placement="right">
                    <div className="auth__input--wrapper">
                        <input
                            type="text"
                            className="auth__input"
                            placeholder="Фамилия"
                            value={lastName}
                            onChange={(e) => handleInputChange(e, 'lastName')}
                        />
                    </div>
                </Tooltip>
                {role === 'admin' && (
                    <Tooltip title={errors.companyName} visible={tooltipVisible === 'companyName'} placement="right">
                        <div className="auth__input--wrapper">
                            <input
                                type="text"
                                className="auth__input"
                                placeholder="Название компании"
                                value={companyName}
                                onChange={(e) => handleInputChange(e, 'companyName')}
                            />
                        </div>
                    </Tooltip>
                )}
                <Tooltip title={errors.phone} visible={tooltipVisible === 'phone'} placement="right">
                    <div className="auth__input--wrapper">
                        <InputMask
                            mask="+7 999 999 99 99"
                            className="auth__input"
                            placeholder="Номер телефона"
                            value={phone}
                            onChange={(e) => handleInputChange(e, 'phone')}
                        />
                    </div>
                </Tooltip>
                <Tooltip title={errors.email} visible={tooltipVisible === 'email'} placement="right">
                    <div className="auth__input--wrapper">
                        <input
                            type="email"
                            className="auth__input"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => handleInputChange(e, 'email')}
                        />
                    </div>
                </Tooltip>
                <Tooltip title={errors.password} visible={tooltipVisible === 'password'} placement="right">
                    <div className="auth__input--wrapper">
                        <input
                            type="password"
                            className="auth__input"
                            placeholder="Пароль"
                            value={password}
                            onChange={(e) => handleInputChange(e, 'password')}
                        />
                    </div>
                </Tooltip>
                <Tooltip title={errors.repeatPassword} visible={tooltipVisible === 'repeatPassword'} placement="right">
                    <div className="auth__input--wrapper">
                        <input
                            type="password"
                            className="auth__input"
                            placeholder="Повторите пароль"
                            value={repeatPassword}
                            onChange={(e) => handleInputChange(e, 'repeatPassword')}
                        />
                    </div>
                </Tooltip>
            </div>
            <div className="auth__sub">
                <div className="auth__btn" onClick={handleRegister}>
                    {loading ? "Регистрация..." : "Регистрация"} <img src={arrow} alt="" />
                </div>
                <div className="auth__reset">
                    Забыли пароль?
                </div>
            </div>
        </div>
    );
};

export default Register;
