import React from "react";
import { Button, Empty } from 'antd';
import { useNavigate } from 'react-router-dom';

const Filters = ({ filters, setShowFiltersAdd }) => {
    const navigate = useNavigate();

    return (
        <div className="profile__emploee profile__filters">
            <div className="profile__emploee--top d-flex justify-content-between align-items-center mb-3">
                <div className="profile__subtitle">Список фильтров</div>
                <Button type="primary" className="profile__emploee--btn second" onClick={() => setShowFiltersAdd(true)}>
                    Добавить
                </Button>
            </div>
            <div className="profile__emploee--block">
                {filters.length > 0 ? (
                    filters.map(filter => (
                        <div className="profile__emploee--item" key={filter.filterId}>
                            <div className="profile__emploee--name">{filter.filterName}</div>
                            <div className="profile__emploee--links">
                                <Button type="link" className="profile__emploee--edit">
                                    Изменить
                                </Button>
                                <Button type="link" className="profile__emploee--role" onClick={() => navigate('/tenders')}>
                                    Перейти
                                </Button>
                            </div>
                        </div>
                    ))
                ) : (
                    <Empty description="Нет фильтров" />
                )}
            </div>
        </div>
    );
};

export default Filters;
