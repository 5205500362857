import logo from '../../img/logo.svg'
import AuthLinks from "../Layout/AuthLinks";
import {NavLink} from "react-router-dom";

const Header = ({id}) => {
    return (
        <header className='header'>
            <NavLink to='/'>
                <img src={logo} alt='logo' className='logo'/>
            </NavLink>
            <menu>
                <NavLink to='/' className={({isActive}) => isActive ? 'active' : ''}>
                    <li>Главная</li>
                </NavLink>
                <NavLink to='/tenders' className={({isActive}) => isActive ? 'active' : ''}>
                    <li>Тендеры</li>
                </NavLink>
                <NavLink to='/plans' className={({isActive}) => isActive ? 'active' : ''}>
                    <li>Тарифы</li>
                </NavLink>
            </menu>
            <AuthLinks id={id}/>
        </header>
    );
};

export default Header;
