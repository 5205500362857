import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";
import './styles/App.scss'
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import {Auth} from "./pages/Auth";
import Profile from "./pages/Profile";
import {Tenders} from "./pages/Tenders";
import {Tender} from "./pages/Tender";
import {AuthProvider} from "./contexts/AuthContext";
import Plans from "./pages/Plans";
import {License} from "./pages/License";
import Dogovor from "./pages/dogovor";
import ProfileWithGuide from "./pages/ProfileWithGuide";
import FiltersAdd from "./components/Profile/FiltersAdd";
import AddFilter from "./pages/AddFilter";

function App() {
    return (
        <Router>
            <AuthProvider>

                <div className="container">
                    <Header/>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/login" element={<Auth/>}/>
                        <Route path="/register" element={<Auth/>}/>
                        <Route path="/profile" element={<Profile/>}/>
                        <Route path="/profile/guide" element={<ProfileWithGuide/>}/>
                        <Route path="/plans" element={<Plans/>}/>
                        <Route path="/priceList" element={<Plans/>}/>
                        <Route path="/tenders" element={<Tenders/>}/>
                        <Route path="/license" element={<License/>}/>
                        <Route path="/oferta" element={<Dogovor/>}/>
                        <Route path="/add-filter" element={<AddFilter/>}/>

                        <Route path="/tender/:tenderId" element={<Tender/>}/>
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                    <Footer/>
                </div>
            </AuthProvider>

        </Router>
    );
}

export default App;
