import React, {useState} from 'react';
import axios from 'axios';
import Modal from 'react-modal';

const Kato = ({onSelect, on, off}) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState({});
    const [history, setHistory] = useState([]);

    const openModal = async () => {
        try {
            const response = await axios.get('https://kato.tend.kz/categories');
            setCategories([{code: '*', name: 'Весь Казахстан', has_children: 0}, ...response.data]);
            setModalIsOpen(true);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const fetchSubcategories = async (category) => {
        if (!subcategories[category.code]) {
            try {
                const response = await axios.get(`https://kato.tend.kz/categories/${category.code}`);
                setSubcategories(prev => ({...prev, [category.code]: response.data}));
                setHistory(prev => [...prev, categories]);
                setCategories(response.data);
            } catch (error) {
                console.error(`Error fetching subcategories for code ${category.code}:`, error);
            }
        } else {
            setHistory(prev => [...prev, categories]);
            setCategories(subcategories[category.code]);
        }
    };

    const handleSelect = (category) => {
        on()
        if (category.code === '*') {
            onSelect({code: '*', name: 'Весь Казахстан'}, [{code: '*'}]);
            off()

        } else {
            fetchSelectedKato(category);
        }
        closeModal();
    };

    const fetchSelectedKato = async (category) => {
        try {
            const response = await axios.get(`https://kato.tend.kz/select/${category.code}`);
            const selectedKato = response.data;
            onSelect(category, selectedKato); // Передаем родительскую категорию и все её дочерние KATO в родительский компонент
            off()
        } catch (error) {
            console.error(`Error selecting KATO with code ${category.code}:`, error);
        }
    };

    const handleBack = () => {
        const previousCategories = history.pop();
        setCategories(previousCategories);
        setHistory([...history]);
    };

    return (
        <div>
            <button onClick={openModal} className='profile__emploee--btn mt24'>Добавить като</button>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Category Selector"
            >
                <div className="profile__emploee--top">
                    <h2 className='profile__name'>Выберите регион</h2>
                    <button onClick={closeModal} className='modal__close'>Закрыть</button>
                </div>
                <div className='profile__emploee--block mt24'>
                    {categories.map(category => (
                        <div className='profile__emploee--item' key={category.code}>
                            <div className="profile__number">
                                {category.name}
                            </div>
                            <div className="profile__btns">
                                {category.has_children > 0 && category.code !== '*' && (
                                    <button className='profile__btn--all'
                                            onClick={() => fetchSubcategories(category)}>Развернуть</button>
                                )}
                                <button className='profile__btn--select'
                                        onClick={() => handleSelect(category)}>Выбрать
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
                {history.length > 0 && <button onClick={handleBack} className="modal__back">Назад</button>}
            </Modal>
        </div>
    );
};

export default Kato;
