import file from "../../img/download.svg";

export const File = ({url}) => {
    return (
        <a href={url} className="file">
            <div className="file__name">Курсы в области управления</div>
            <div className="file__btn">
                <img src={file} alt=""/>
            </div>
        </a>
    )
}
