import React, {useEffect, useMemo, useState} from "react";
import {Link, useNavigate} from "react-router-dom";

const Company = ({profile}) => {
    const [isAdmin, setIsAdmin] = useState(false);
    const navigate = useNavigate();
    const [plan, setPlan] = useState(false);

    const expirationDate = useMemo(() => new Date(profile.planExpiration), [profile.planExpiration]);
    const currentDate = useMemo(() => new Date(), []);

    useEffect(() => {
        if (expirationDate < currentDate) {
            setPlan(true);
            // Swal.fire({
            //     icon: 'warning',
            //     title: 'Тариф истек!',
            //     text: 'Ваш тарифный план истек. Пожалуйста, выберите новый план.',
            //     showCancelButton: true,
            //     confirmButtonText: 'Ок',
            //     cancelButtonText: 'Пополнить баланс'
            // }).then((result) => {
            //     if (result.dismiss === Swal.DismissReason.cancel) {
            //         navigate('/plans');  // Пополнить баланс
            //     }
            // });
        }
    }, [expirationDate, currentDate, navigate]);

    useEffect(() => {
        if (profile.role === 'admin') {
            setIsAdmin(true);
        } else {
            setIsAdmin(false);
        }
    }, [profile]);

    return (
        <div className="profile__company">
            <div className="profile__company--info">
                <div className="profile__name">{profile.companyName} <Link to={'/plans'}>Улучшить</Link></div>
                <div className="profile__number">{isAdmin ? 'Администратор' : "Сотрудник"}</div>

                <div className="profile__number">Тариф - <span>{plan ?
                    <span style={{color: 'red'}}>истёк</span> : profile.planName}</span></div>
            </div>
            <div className='profile__company--emps'>
                <div className="profile__company--emp">
                    <span className='profile__name'>{profile.employeeCount}</span>
                    <p className='profile__number'>сотрудника</p>
                </div>
                <div className="profile__company--emp">
                    <span className='profile__name'>{profile.maxFilters}</span>
                    <p className='profile__number'>фильтров</p>
                </div>
                <div className="profile__company--emp">
                    <span className='profile__name'>{profile.companyBalance || 0} ₸</span>
                    <p className='profile__number'>баланс</p>
                </div>
            </div>
        </div>
    );
};

export default Company;
