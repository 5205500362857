import reset from '../../img/reset.svg';


export const Sidebar = ({filters, toggleFilter, activeFilters}) => {
    return (
        <>
            <div className="sidebar">
                <div className="sidebar__top">
                    <div className="sidebar__title">Настроить ленту</div>
                    <div className="sidebar__reset">
                        <img src={reset} alt=""/>
                    </div>
                </div>
                <div className="filter__block sidebar__filter">
                    <div className="filter__item sidebar__item">
                        <span className='sidebar__sub'>Фильтры</span>

                        {filters.map(filter => (
                            <div key={filter.id} className="checkbox-container">
                                <input
                                    type="checkbox"
                                    id={`checkbox${filter.id}`}
                                    checked={activeFilters.includes(filter.id) || activeFilters.length === 0}
                                    onChange={() => toggleFilter(filter.id)}
                                />
                                <label htmlFor={`checkbox${filter.id}`}>{filter.name}</label>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}
